import { ApiService } from "@/core/shared/services/ApiService";
import type {
  Rule,
  RuleEditionFormData,
  Ruleset,
  RulesSorting,
  RuleUpdated,
} from "@/modules/rule/services/RuleService.types";

/**
 * @class RuleService
 */
export default class RuleService {
  /**
   * @returns
   * @param ruleId
   * @param projectId
   */
  static get(ruleId: number, projectId: number): Promise<Rule> {
    return ApiService.get(`/project/${projectId}/rule/${ruleId}`);
  }

  /**
   * @returns
   * @param projectId
   * @param data
   */
  static create(
    projectId: number,
    data: RuleEditionFormData,
  ): Promise<RuleUpdated> {
    return ApiService.post(`/project/${projectId}/rule`, data);
  }

  /**
   * @returns
   * @param ruleId
   * @param projectId
   */
  static delete(ruleId: number, projectId: number): Promise<RuleUpdated> {
    return ApiService.delete(`/project/${projectId}/rule/${ruleId}`);
  }

  /**
   * @returns
   * @param ruleId
   * @param projectId
   * @param data
   */
  static update(
    ruleId: number,
    projectId: number,
    data: RuleEditionFormData,
  ): Promise<RuleUpdated> {
    return ApiService.put(`/project/${projectId}/rule/${ruleId}`, data);
  }

  /**
   * @returns
   * @param projectId
   */
  static list(projectId: number): Promise<Rule[]> {
    return ApiService.get(`/project/${projectId}/rules`);
  }

  /**
   * @returns
   * @param projectId
   * @param order
   */
  static sort(projectId: number, order: RulesSorting): Promise<Rule[]> {
    return ApiService.put(`/project/${projectId}/rules/sort`, {
      sort: order,
    });
  }

  /**
   * @returns
   * @param projectId
   * @param presetId
   */
  static addRuleset(projectId: number, presetId: number): Promise<RuleUpdated> {
    return ApiService.post(`/project/${projectId}/preset-rule`, {
      preset_id: presetId,
    });
  }

  /**
   * @returns
   * @param projectId
   */
  static getRulesets(projectId: number): Promise<Ruleset[]> {
    return ApiService.get(`/project/${projectId}/presets`);
  }
}
