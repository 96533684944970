import type {
  AccountInfo,
  AccountToken,
  AccountUpdated,
  ContactResponse,
} from "@/core/modules/account/services/AccountService.types";
import { ApiService } from "@/core/shared/services/ApiService";
import type { RouteParamValue } from "vue-router";

/**
 * @class AccountService
 */
export default class AccountService {
  /**
   * Register Company Account
   * @throws {ApiServiceError}
   * @returns {Promise<AccountToken>}
   * @param data
   */
  static register(data: FormData): Promise<AccountToken> {
    return ApiService.post("/user", data);
  }

  /**
   * Get Information of Account
   * @throws {ApiServiceError}
   * @return {Promise<AccountInfo>}
   */
  static getInfo(): Promise<AccountInfo> {
    return ApiService.get("/user");
  }

  /**
   * Login Account
   * @param email {string} Email
   * @param password {string} Password
   * @throws {ApiServiceError}
   * @return {Promise<AccountToken>}
   */
  static login(email: string, password: string): Promise<AccountToken> {
    return ApiService.post("/user/login", {
      email: email,
      password: password,
    });
  }

  /**
   * Login with Facebook
   * @throws {ApiServiceError}
   * @return {Promise<AccountToken>}
   * @param data
   */
  static loginWithFacebook(data: FormData): Promise<AccountToken> {
    return ApiService.post("/user/facebook-connect", data);
  }

  /**
   * Login with google
   * @throws {ApiServiceError}
   * @return {Promise<AccountToken>}
   * @param data
   */
  static loginWithGoogle(data: FormData): Promise<AccountToken> {
    return ApiService.post("/user/google-login", data);
  }

  /**
   * Login with Apple
   * @throws {ApiServiceError}
   * @return {Promise<AccountToken>}
   * @param data
   */
  static loginWithApple(data: FormData): Promise<AccountToken> {
    return ApiService.post("/user/apple-connect", data);
  }

  /**
   * Logout Account
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static logout(): Promise<AccountUpdated> {
    return ApiService.delete("/user/logout");
  }

  /**
   * Check Email Verification
   * @param code {string} Code
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static checkEmailVerification(
    code: string | RouteParamValue[],
  ): Promise<AccountUpdated> {
    return ApiService.put("/user/verify-email", {
      code: code,
    });
  }

  /**
   * Resend Email Verification
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static resendEmailVerification(): Promise<AccountUpdated> {
    return ApiService.post("/user/resend-verification-email");
  }

  /**
   * Set Email
   * @param email {String} Email
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static setEmail(email: string): Promise<AccountUpdated> {
    return ApiService.put("/user/email", {
      email: email,
    });
  }

  /**
   * Update Email
   * @param email {string} Email
   * @param password {string} Password
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static updateEmail(email: string, password: string): Promise<AccountUpdated> {
    return ApiService.post("/user/change-email", {
      email: email,
      password: password,
    });
  }

  /**
   * Request Password Reset
   * @param email {string} Email
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static requestPasswordReset(email: string): Promise<AccountUpdated> {
    return ApiService.post("/user/lost-password", {
      email: email,
    });
  }

  /**
   * Reset Password
   * @param code {string} Code
   * @param password {string} Password
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static resetPassword(
    code: string,
    password: string,
  ): Promise<AccountUpdated> {
    return ApiService.put("/user/lost-password", {
      code: code,
      password: password,
    });
  }

  /**
   * Updated Password
   * @param oldPassword {string} Old password
   * @param newPassword {string} New password
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   */
  static updatePassword(
    oldPassword: string,
    newPassword: string,
  ): Promise<AccountUpdated> {
    return ApiService.put("/user/password", {
      old_password: oldPassword,
      new_password: newPassword,
    });
  }

  /**
   * Updated Password
   * @throws {ApiServiceError}
   * @return {Promise<AccountUpdated>}
   * @param data
   */
  static update(data: FormData): Promise<AccountUpdated> {
    return ApiService.put("/user", data);
  }

  /**
   * Sets billing
   * @param data
   * @returns billing
   */
  static async setBilling(data: FormData): Promise<object> {
    return ApiService.put("/user/billing-address", data);
  }

  /**
   * Send message to support
   * @param name {string} Name
   * @param email {string} Email
   * @param message {string} Message
   * @throws {ApiServiceError}
   * @return {Promise<ContactResponse>}
   */
  static contact(
    name: string,
    email: string,
    message: string,
  ): Promise<ContactResponse> {
    return ApiService.post(`/contact`, {
      name,
      email,
      message,
    });
  }
}
