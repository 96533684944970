import { useChatStore } from "@/store/ChatStore";

export interface ChatMessageSocket {
  chat_id: number;
  message: string;
  sequence_id: number;
  user_type: string;
  image: string;
  date_created: number;
}

export class ChatHandler {
  static storeMessage(chatMessage: ChatMessageSocket) {
    useChatStore().setIncomeMessage(chatMessage);
  }
}
