<template>
  <div
    @click="goToAction(notification.id)"
    :class="[
      'notification-card',
      { 'bg-teal-50': !(notification.date_read && notification.date_read > 0) },
      { 'sm:p-5': isSidebar },
    ]"
    data-testid="notification-card"
  >
    <ImageCloud
      :class="[
        'object-contain shrink-0 rounded mb-3 w-11 h-11 sm:mb-0 sm:mr-6 sm:w-[72px] sm:h-[72px]',
        { 'w-11 h-11 sm:w-11 sm:h-11 sm:mr-4': isSidebar },
      ]"
      :src="notification.image_url"
      :alt="notification.title"
    />
    <div :class="['sm:pr-6 break-words', { 'sm:pr-0 break-words': isSidebar }]">
      <div class="font-medium text-sm text-gray-900 mb-1 flex space-x-1">
        <span>{{ notification.title }}</span>
        <Component
          v-if="notification.app_notification_style && iconType"
          :is="iconType.icon"
          :class="['w-4 h-4 shrink-0', iconType.fill]"
        />
        <time
          :class="[
            'text-gray-500 whitespace-nowrap text-xs font-normal',
            { 'ml-1': !isSidebar },
          ]"
        >
          {{ createdOn }}
        </time>
      </div>
      <p :class="['notification-description', { open: !descriptionCropped }]">
        <span v-if="descriptionMore && descriptionCropped">
          {{ notification.message.slice(0, descriptionLimit) }}...
        </span>
        <span v-else>{{ notification.message }}</span>
      </p>
      <button
        v-if="descriptionMore"
        @click.stop="descriptionCropped = !descriptionCropped"
        class="flex items-center font-medium text-sm text-gray-900 mt-2"
      >
        <span v-if="descriptionCropped">
          {{ $t("common.button.expand") }}
        </span>
        <span v-else> {{ $t("common.button.collapse") }} </span>
        <ChevronDownSolidIcon
          :class="[
            'align-middle w-4 h-4 ml-1 transition duration-300',
            { 'rotate-180': !descriptionCropped },
          ]"
        />
      </button>
    </div>
    <div
      @click.stop
      v-if="!isSidebar"
      class="absolute top-4 right-2 sm:top-6 sm:right-4"
    >
      <Dropdown :items="dropdownItems" width="w-36">
        <template #button>
          <DotsVerticalOutlineIcon class="h-5 w-5 block fill-gray-900" />
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";

import Dropdown from "@/core/shared/components/Dropdown/Dropdown.vue";
import ImageCloud from "@/core/shared/components/Image/ImageCloud.vue";

import ChevronDownSolidIcon from "@/shared/components/icon/ChevronDownSolidIcon.vue";
import CircleCheckSolidIcon from "@/shared/components/icon/CircleCheckSolidIcon.vue";
import DotsVerticalOutlineIcon from "@/shared/components/icon/DotsVerticalOutlineIcon.vue";
import ErrorOutlineIcon from "@/shared/components/icon/ErrorOutlineIcon.vue";

import type { DropdownItem } from "@/core/shared/components/Dropdown/Dropdown.types";
import type { AppNotification } from "@/modules/notification/services/NotificationService.types";

import AppNotifier from "@/core/shared/helpers/Notifier/AppNotifier";
import NotificationRouterLink from "@/modules/notification/helpers/NotificationRouterLink";

interface IconData {
  icon: string;
  fill: string;
}

export default defineComponent({
  name: "NotificationCard",
  components: {
    ChevronDownSolidIcon,
    DotsVerticalOutlineIcon,
    Dropdown,
    ImageCloud,
    CircleCheckSolidIcon,
    ErrorOutlineIcon,
  },
  props: {
    notification: {
      type: Object as PropType<AppNotification>,
      required: true,
    },
    isSidebar: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["markRead", "delete", "closeSidebar"],
  data() {
    return {
      descriptionLimit: 75,
      descriptionCropped: true,
      dropdownItems: [] as DropdownItem[],
    };
  },
  created() {
    this.setDropdownItems();
    if (window.innerWidth < 640) {
      this.descriptionLimit = 115;
    }
  },
  computed: {
    iconType(): IconData {
      if (this.notification.app_notification_style === AppNotifier.SUCCESS) {
        return { icon: "CircleCheckSolidIcon", fill: "text-green-400" };
      }
      if (this.notification.app_notification_style === AppNotifier.CRITICAL) {
        return { icon: "ErrorOutlineIcon", fill: "text-red-500" };
      }
      return {} as IconData;
    },
    createdOn() {
      return this.notification.date_created
        ? this.$datetime.formatUnixTime(this.notification.date_created)
        : "";
    },
    descriptionMore(): boolean {
      return (
        this.isSidebar &&
        Number(this.notification.message.length) > this.descriptionLimit
      );
    },
  },
  methods: {
    goToAction(notificationId: number): void {
      if (this.isSidebar) {
        if (this.notification.date_read === 0) {
          this.$emit("markRead", notificationId);
        }
        this.$emit("closeSidebar");
      }
      this.$router.push(NotificationRouterLink.get(this.notification));
    },
    setDropdownItems() {
      this.dropdownItems = [
        {
          label: this.$t("common.button.markRead"),
          action: () => this.$emit("markRead", this.notification.id),
          cssClasses:
            Number(this.notification.date_read) > 0
              ? "hidden"
              : "pt-4 text-gray-900",
        },
        {
          label: this.$t("common.button.removeFromList"),
          action: () => this.$emit("delete", this.notification.id),
          cssClasses:
            Number(this.notification.date_read) > 0
              ? "last:py-3 text-gray-900"
              : "pb-4 text-gray-900",
        },
      ];
    },
  },
});
</script>

<style lang="scss" scoped>
.notification-card {
  @apply p-4 border-b relative sm:p-6 sm:flex hover:bg-gray-50 cursor-pointer;

  .notification-description {
    @apply max-h-16  sm:max-h-10 overflow-hidden text-gray-500 text-sm font-normal;
    &.open {
      @apply max-h-full  transition-all duration-500;
    }
  }
}
</style>
