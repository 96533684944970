<template>
  <div class="authenticated-layout-header">
    <OffCanvas
      v-model:is-open="showMenu"
      :items="contextMenuItems"
      :items-account="accountMenuItems"
    />
    <NotificationsSidebar v-model:show="showNotifications" />
    <AuthenticatedNavbar
      v-model:off-canvas-value="showMenu"
      v-model:notification-value="showNotifications"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import AuthenticatedNavbar from "@/core/shared/components/Navigation/AuthenticatedNavbar.vue";
import OffCanvas from "@/core/shared/components/Navigation/OffCanvas.vue";

import NotificationsSidebar from "@/modules/notification/components/NotificationsSidebar.vue";

import type { NavigationItem } from "@/core/shared/components/Navigation/Navigation.types";

import { ModalHandler } from "@/core/shared/helpers/Modal/ModalHandler";

export default defineComponent({
  name: "AuthenticatedLayoutHeader",
  components: {
    AuthenticatedNavbar,
    OffCanvas,
    NotificationsSidebar,
  },
  data() {
    return {
      showMenu: false,
      showNotifications: false,
      contextMenuItems: [] as NavigationItem[],
      accountMenuItems: [] as NavigationItem[],
    };
  },
  created() {
    this.setContextMenu();
    this.setAccountMenuItems();
  },
  methods: {
    setAccountMenuItems() {
      this.accountMenuItems = [
        {
          label: this.$t("core.shared.components.layout.navbar.account"),
          route: {
            name: "AccountOverview",
          },
        },
        {
          label: this.$t("core.shared.components.layout.navbar.subscription"),
          route: {
            name: "SubscriptionOverview",
          },
        },
        {
          label: this.$t("core.shared.components.layout.navbar.contact"),
          route: {},
          action: () => this.onOpenModal(),
        },
      ];
    },
    setContextMenu() {
      this.contextMenuItems = [
        {
          label: this.$t("core.shared.components.layout.navbar.dashboard"),
          route: {
            name: "Dashboard",
          },
        },
      ];
    },
    onOpenModal() {
      ModalHandler.open("contact-support-modal");
    },
  },
});
</script>
