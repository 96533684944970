import DashboardGuard from "@/modules/dashboard/helpers/DashboardGuard";
import ProjectGuard from "@/modules/project/components/ProjectGuard";

export default [
  {
    path: "/project",
    beforeEnter: DashboardGuard.withCompleteOnboarding,
    children: [
      {
        path: ":projectId",
        name: "ProjectOverview",
        component: () => import("@/modules/project/views/ProjectOverview.vue"),
        beforeEnter: ProjectGuard.belongsToUser,
        meta: {
          title: "modules.project.views.projectOverview.meta.title",
          layout: "ProjectLayout",
        },
      },
      {
        path: ":projectId/traffic",
        name: "ProjectTraffic",
        component: () => import("@/modules/project/views/ProjectTraffic.vue"),
        beforeEnter: ProjectGuard.belongsToUser,
        meta: {
          title: "modules.project.views.projectTraffic.meta.title",
          layout: "ProjectLayout",
        },
      },
      {
        path: ":projectId/rules",
        name: "ProjectRules",
        component: () => import("@/modules/project/views/ProjectRules.vue"),
        beforeEnter: ProjectGuard.belongsToUser,
        meta: {
          title: "modules.project.views.projectRules.meta.title",
          layout: "ProjectLayout",
        },
      },
      {
        path: ":projectId/ip-addresses",
        name: "ProjectIpAddresses",
        component: () =>
          import("@/modules/project/views/ProjectIpAddresses.vue"),
        beforeEnter: ProjectGuard.belongsToUser,
        meta: {
          title: "modules.project.views.projectIpAddresses.meta.title",
          layout: "ProjectLayout",
        },
      },
      {
        path: ":projectId/installation",
        name: "ProjectInstallation",
        component: () =>
          import("@/modules/project/views/ProjectInstallation.vue"),
        beforeEnter: ProjectGuard.belongsToUser,
        meta: {
          title: "modules.project.views.projectInstallation.meta.title",
          layout: "ProjectLayout",
        },
      },
      {
        path: ":projectId/settings",
        name: "ProjectSettings",
        component: () => import("@/modules/project/views/ProjectSettings.vue"),
        beforeEnter: ProjectGuard.belongsToUser,
        meta: {
          title: "modules.project.views.projectSettings.meta.title",
          layout: "ProjectLayout",
        },
      },
      {
        path: "create",
        name: "ProjectCreation",
        component: () => import("@/modules/project/views/ProjectCreation.vue"),
        meta: {
          title: "modules.project.views.projectCreation.meta.title",
          layout: "ProjectCreationLayout",
        },
      },
    ],
  },
];
