import Notifier from "@/core/shared/helpers/Notifier/Notifier";
import i18n from "@/i18n";
import { useNetworkStore } from "@/store/NetworkStore";
import { useNotifierStore } from "@/store/NotifierStore";

export default {
  install() {
    // configure the app
    window.addEventListener("offline", () => {
      useNetworkStore().setOffline();
      if (useNotifierStore().networkNotificationId === 0) {
        const id = Notifier.sendInfo({
          message: i18n.global.t("common.error.global.offline"),
          duration: 0,
          enableClose: false,
          position: "top-0 right-0",
          overlay: true,
        });
        useNotifierStore().setNetworkId(id);
      }
    });

    window.addEventListener("online", () => {
      useNetworkStore().setOnline();
      Notifier.deleteNotification(useNotifierStore().networkNotificationId);
      useNotifierStore().setNetworkId(0);
    });
  },
};
