import { useModalStore } from "@/store/ModalStore";

export class ModalHandler {
  static open(id = "modal") {
    useModalStore().beforeOpen(id);
    useModalStore().open({
      show: true,
      id,
    });
  }

  static close(id = "modal") {
    useModalStore().beforeClose(id);
    useModalStore().close(id);
  }

  static closeAll() {
    useModalStore().closeAll();
  }
}
