import AuthService from "@/core/shared/services/Auth/AuthService";
import router from "@/router";
import type { ApiServiceError } from "@/core/shared/services/Error/ApiServiceError";
import {
  MaintenanceApiServiceError,
  UnauthorizedApiServiceError,
} from "@/core/shared/services/Error/ApiServiceError";
import { DsFlashNotifier } from "@devsalsa/vue-core";
import { ErrorTranslator } from "@/core/shared/helpers/Error/ErrorTranslator";
import { MaintenanceHandler } from "@/core/shared/helpers/Maintenance/MaintenanceHandler";

export class ErrorHandler {
  static handle(error: unknown): void {
    if (error instanceof UnauthorizedApiServiceError) {
      this.handleUnauthorized(error);
    } else if (error instanceof MaintenanceApiServiceError) {
      this.handleMaintenance(error);
    }
  }

  private static handleUnauthorized(error: ApiServiceError): void {
    if (AuthService.isAuthenticated()) {
      AuthService.logout();
    }
    if (router.currentRoute.value.name !== "Login") {
      router.push({
        name: "Login",
      });
    }
    DsFlashNotifier.error(ErrorTranslator.translate(error));
  }

  private static handleMaintenance(error: ApiServiceError): void {
    MaintenanceHandler.setOn(error);
  }
}
