<template>
  <div
    ref="toast"
    v-if="!close"
    class="app-toast"
    :class="`app-toast-${themeColor}`"
    @mouseenter="pausedTimer(true)"
    @mouseleave="pausedTimer(false)"
    data-testid="app-toast-notification"
  >
    <div class="flex justify-between gap-x-4 mr-6">
      <!-- image -->
      <img
        :src="message.image_url"
        alt="image-url"
        class="w-16 h-16 object-scale-down rounded-md"
      />
      <!-- message -->
      <div
        class="space-y-1 flex flex-col text-left max-w-xs break-words line-clamp-6"
        @click="onClick"
      >
        <p
          class="text-sm font-medium text-gray-900"
          data-testid="app-toast-notification-title"
        >
          {{ message.title }}
        </p>
        <p
          v-html="message.message"
          class="break-words line-clamp-6 font-normal text-sm text-gray-500"
          data-testid="app-toast-notification-message"
        />
      </div>
      <!-- close -->
      <span
        v-if="enableClose"
        class="cursor-pointer absolute top-4 right-4 text-gray-500 w-5"
        @click="onClose"
        data-testid="app-toast-notification-close"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </span>
    </div>
    <!-- animation -->
    <div v-if="duration > 0" class="w-auto bg-gray-200 mt-2">
      <div
        class="app-toast-progress-bar h-0.5"
        :class="[
          `app-toast-progress-bar-${themeColor}`,
          {
            'app-toast-animation-paused': isPaused,
            'app-toast-animation-resume': !isPaused,
          },
        ]"
        :style="animation"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getUnixTime } from "date-fns";
import type { PropType } from "vue";

import type { AppNotification } from "@/modules/notification/services/NotificationService.types";

import AppNotifier from "@/core/shared/helpers/Notifier/AppNotifier";
import NotificationRouterLink from "@/modules/notification/helpers/NotificationRouterLink";

/**
 * ApiToast component
 */
export default defineComponent({
  name: "AppToast",
  components: {},
  props: {
    unique: {
      type: Number,
      required: true,
    },
    message: {
      type: Object as PropType<AppNotification>,
      required: true,
    },
    themeColor: {
      type: String,
      required: false,
      default: "blue",
    },
    duration: {
      type: Number,
      default: 10,
    },
    enableClose: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    animation(): string {
      return `animation: ${this.duration}s linear app-toast-theme-color, ${this.duration}s ease-out app-toast-enter forwards`;
    },
  },
  data() {
    return {
      close: false,
      isPaused: false,
      timer: {
        id: 0,
        rest: 0,
        start: 0,
        pause: 0,
      },
    };
  },
  created() {
    this.timer.start = getUnixTime(new Date());
    this.timer.pause = this.timer.start;
    this.timer.rest = this.duration;

    if (this.duration > 0) {
      this.timer.id = this.timerClock();
    }
  },
  mounted() {
    const previousToast: HTMLDivElement = this.$parent?.$el.firstElementChild;
    previousToast.insertAdjacentElement(
      "beforebegin",
      this.$refs.toast as HTMLDivElement,
    );
  },
  methods: {
    onClose() {
      this.close = true;
      AppNotifier.deleteStoreNotification(this.unique);
    },
    onClick() {
      this.$router.push(NotificationRouterLink.get(this.message));
    },
    timerClock() {
      return window.setTimeout(() => {
        this.onClose();
      }, this.timer.rest * 1000);
    },
    pausedTimer(flag: boolean): void {
      //Don't manage timer if set duration equal to 0
      if (this.duration === 0) {
        return;
      }

      this.isPaused = flag;
      if (this.isPaused) {
        clearTimeout(this.timer.id);
        this.timer.pause = getUnixTime(new Date()) - this.timer.start;
        this.timer.rest = this.timer.rest - this.timer.pause;
      } else {
        this.timer.start = getUnixTime(new Date());
        this.timer.id = this.timerClock();
      }
    },
  },
});
</script>

<style lang="scss">
@keyframes app-toast-theme-color {
  100% {
    background-position: 0;
  }

  0% {
    background-position: 100%;
  }
}

@keyframes app-toast-enter {
  100% {
    transform: scaleX(0);
  }
  0% {
    transform: scaleX(1);
  }
}
</style>

<style lang="scss" scoped>
.app-toast {
  @apply h-auto border-l-2 p-4 rounded-r-md bg-white shadow-white flex flex-col cursor-pointer max-w-[300px] md:max-w-max z-30;

  &-blue {
    @apply border-l-blue-500;
  }

  &-green {
    @apply border-l-green-500;
  }

  &-yellow {
    @apply border-l-yellow-500;
  }

  &-red {
    @apply border-l-red-500;
  }

  &-gray {
    @apply border-l-gray-500;
  }
}

.app-toast-progress-bar {
  @apply bg-gray-300;
  transform-origin: right;

  &-blue {
    @apply bg-gradient-to-r from-blue-600 to-blue-300;
  }

  &-green {
    @apply bg-gradient-to-r from-green-600 to-green-300;
  }

  &-yellow {
    @apply bg-gradient-to-r from-yellow-600 to-yellow-300;
  }

  &-red {
    @apply bg-gradient-to-r from-red-500 to-red-300;
  }
}

.app-toast-animation-paused {
  animation-play-state: paused !important;
}

.app-toast-animation-resume {
  animation-play-state: running !important;
}
</style>
