<template>
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.0085 2.08686C4.2945 0.750668 6.18134 0 8.00002 0C9.81871 0 11.6795 0.867292 12.9655 2.20349C14.2515 3.53969 14.974 5.35196 14.974 7.24162L14.974 11.25L15.6654 11.9729C15.8252 12.139 15.934 12.3506 15.9781 12.5809C16.0221 12.8112 15.9995 13.0499 15.913 13.2669C15.8265 13.4839 15.6801 13.6693 15.4922 13.7998C15.3043 13.9303 15.0834 13.9999 14.8574 14H1.14265C0.916641 13.9999 0.695722 13.9303 0.507819 13.7998C0.319917 13.6693 0.173467 13.4839 0.0869844 13.2669C0.000501676 13.0499 -0.022131 12.8112 0.0219475 12.5809C0.066026 12.3506 0.174837 12.139 0.334624 11.9729L1 11.1334L1.00002 7.125C1.00002 5.23533 1.72249 3.42306 3.0085 2.08686ZM8 18C7.20435 18 6.44129 17.6839 5.87868 17.1213C5.31607 16.5587 5 15.7956 5 15H11C11 15.7956 10.6839 16.5587 10.1213 17.1213C9.55871 17.6839 8.79565 18 8 18Z"
      fill="#6B7280"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppNotificationSolidIcon",
});
</script>
