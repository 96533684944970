<template>
  <header class="authenticated-navbar" data-testid="authenticated-navbar">
    <!-- Logo-->
    <div class="px-3 md:px-4 xl:px-6 flex xl:mx-auto">
      <DsLink class="flex items-center h-16" :to="{ name: 'Dashboard' }">
        <img :src="logo" alt="NetIngest" />
      </DsLink>
      <!-- Links navbar lg-->
      <div
        v-if="$grid('hidden lg:block')"
        class="flex items-center justify-center mx-8"
      >
        <NavbarItem
          :to="{ name: 'Dashboard' }"
          route-name="Dashboard"
          :text="$t('core.shared.components.layout.navbar.dashboard')"
          class="mr-8"
        />
      </div>
      <!--		Avatar & notification icons-->
      <div class="ml-auto flex items-center">
        <div v-if="$grid('lg:hidden')" class="flex items-center">
          <button @click="showMenu = !showMenu">
            <MenuOutlineIcon class="h-8 w-8 text-gray-900" />
          </button>
        </div>
        <div v-if="$grid('hidden lg:block')">
          <Dropdown :items="items" close-on-mouse-leave>
            <template #button>
              <DsAvatar
                :alt="fullName"
                size="sm"
                data-testid="avatar-dropdown-menu"
              />
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import logo from "@/assets/img/logo-small.png";
import { useAppNotifierStore } from "@/store/AppNotifierStore";
import { useChatStore } from "@/store/ChatStore";

import { DsAvatar, DsLink } from "@devsalsa/vue-core";

import Dropdown from "@/core/shared/components/Dropdown/Dropdown.vue";
import NavbarItem from "@/core/shared/components/Navigation/NavbarItem.vue";

import MenuOutlineIcon from "@/shared/components/icon/MenuOutlineIcon.vue";

import type { DropdownItem } from "@/core/shared/components/Dropdown/Dropdown.types";

import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";

export default defineComponent({
  name: "AuthenticatedNavbar",
  components: {
    DsAvatar,
    MenuOutlineIcon,
    NavbarItem,
    DsLink,
    Dropdown,
  },
  props: {
    offCanvasValue: {
      required: true,
      type: Boolean,
    },
    notificationValue: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    logo() {
      return logo;
    },
    showMenu: {
      get(): boolean {
        return this.offCanvasValue;
      },
      set(val: boolean) {
        this.$emit("update:offCanvasValue", val);
      },
    },
    showNotifications: {
      get(): boolean {
        return this.notificationValue;
      },
      set(notificationVal: boolean) {
        this.$emit("update:notificationValue", notificationVal);
      },
    },
    fullName(): string {
      return `${this.accountInfo.first_name || ""} ${
        this.accountInfo.last_name || ""
      }`;
    },
    hasNotifications(): boolean {
      return useAppNotifierStore().unreadNotifications > 0;
    },
    hasNewChats(): boolean {
      return useChatStore().unreadChats > 0;
    },
  },
  data() {
    return {
      accountInfo: computed(
        () =>
          AccountHandler.accountInfo.value ?? {
            first_name: "",
            last_name: "",
          },
      ),
      items: [
        {
          label: this.$t("core.shared.components.layout.navbar.account"),
          route: {
            name: "AccountOverview",
          },
        },
        {
          label: this.$t("core.shared.components.layout.navbar.subscription"),
          route: {
            name: "SubscriptionOverview",
          },
        },
        {
          label: this.$t("core.shared.components.layout.navbar.paymentMethod"),
          route: {
            name: "PaymentMethod",
          },
        },
        {
          label: this.$t("core.shared.components.layout.navbar.billing"),
          route: {
            name: "BillingOverview",
          },
        },
        {
          label: this.$t("common.route.logout.link"),
          route: {
            name: "Logout",
          },
          cssClasses: "border-t border-gray-100",
        },
      ] as DropdownItem[],
    };
  },
});
</script>

<style lang="scss" scoped>
.authenticated-navbar {
  @apply w-full h-16 bg-white border-b border-gray-200;

  :deep(.ds-avatar-letter) {
    @apply bg-purple-700;
  }
}
</style>
