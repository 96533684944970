import * as Sentry from "@sentry/vue";
import { ClearLocalData } from "@/core/shared/helpers/LocalData";

export default class AuthService {
  /**
   * Save the token in the local storage.
   *
   * @param token
   */
  static setToken(token: string): void {
    localStorage.token = token;
  }

  /**
   * Get the curren token stored in the local storage.
   *
   * @returns string
   */
  static getToken(): string {
    return localStorage.token;
  }

  /**
   * @returns boolean
   */
  static isAuthenticated(): boolean {
    return this.getToken() != null;
  }

  /**
   * Remove the token from the local storage
   */
  static logout(): void {
    if (import.meta.env.VITE_APP_SENTRY == "TRUE") {
      Sentry.configureScope((scope) => scope.setUser(null));
    }
    //Clear all keys from local storage
    ClearLocalData();
    localStorage.removeItem("token");
  }
}
