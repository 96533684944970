<template>
  <div class="project-creation-layout">
    <AuthenticatedLayoutHeader />
    <div class="project-creation-layout-content">
      <RouterView v-slot="{ Component, route }">
        <transition name="fade" mode="out-in">
          <component :is="Component" :key="route.name" />
        </transition>
      </RouterView>
    </div>
    <AuthenticatedFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import AuthenticatedFooter from "@/core/shared/components/Layout/AuthenticatedFooter.vue";
import AuthenticatedLayoutHeader from "@/core/shared/components/Layout/AuthenticatedLayoutHeader.vue";

export default defineComponent({
  name: "ProjectCreationLayout",
  components: {
    AuthenticatedLayoutHeader,
    AuthenticatedFooter,
  },
});
</script>

<style lang="scss" scoped>
.project-creation-layout {
  &-content {
    @apply flex justify-center items-center
    bg-gray-50 lg:bg-white
    min-h-[calc(100vh_-_64px)]
    w-full h-full px-6 py-8;
  }
}
</style>
