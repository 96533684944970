<template>
  <aside class="p-6">
    <div class="flex flex-col flex-grow overflow-y-auto">
      <div
        class="flex flex-col pb-6 mb-6 border-purple-200 border-b-4 border-double"
      >
        <h1 class="text-lg font-semibold text-gray-900">
          {{ projectName }}
        </h1>
      </div>
      <div class="flex-grow flex flex-col">
        <nav class="flex-1 space-y-4">
          <ProjectMenuItem
            :to="{ name: 'ProjectOverview' }"
            route-name="ProjectOverview"
            :title="$t('modules.project.menu.overview')"
          />
          <ProjectMenuItem
            :to="{ name: 'ProjectTraffic' }"
            route-name="ProjectTraffic"
            :title="$t('modules.project.menu.traffic')"
          />
          <ProjectMenuItem
            :to="{ name: 'ProjectRules' }"
            route-name="ProjectRules"
            :title="$t('modules.project.menu.rules')"
          />
          <ProjectMenuItem
            :to="{ name: 'ProjectIpAddresses' }"
            route-name="ProjectIpAddresses"
            :title="$t('modules.project.menu.ipAddresses')"
          />
          <ProjectMenuItem
            :to="{ name: 'ProjectSettings' }"
            route-name="ProjectSettings"
            :title="$t('modules.project.menu.settings')"
          />
          <ProjectMenuItem
            :to="{ name: 'ProjectInstallation' }"
            route-name="ProjectInstallation"
            :title="$t('modules.project.menu.installation')"
          />
        </nav>
      </div>
    </div>
  </aside>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { useRoute } from "vue-router";

import ProjectMenuItem from "@/modules/project/components/menu/ProjectMenuItem.vue";

import type { Project } from "@/modules/project/services/ProjectService.types";

defineOptions({
  name: "ProjectMenu",
});

const route = useRoute();
const project = ref(route.meta.project as Project);

const projectName = computed((): string => {
  return project.value.name;
});
</script>
