<template>
  <header class="unauthenticated-header">
    <a class="flex items-center" href="https://netingest.com/" target="_self">
      <img :src="logo" alt="NetIngest" />
    </a>
    <div class="flex space-x-1" v-if="showRegisterLinks">
      <span class="text-gray-500 text-sm sm:block hidden">
        {{ $t("core.modules.account.register.links.account") }}
      </span>
      <DsLink
        :to="{ name: 'Login' }"
        data-testid="login-link"
        class="header-link text-gray-900"
      >
        {{ $t("core.modules.account.register.links.signIn") }}
      </DsLink>
    </div>
    <div class="flex space-x-1" v-if="showLoginLinks">
      <span class="text-gray-500 text-sm sm:block hidden">
        {{ $t("core.modules.account.login.text.account") }}
      </span>
      <DsLink
        :to="{ name: 'Register' }"
        data-testid="register-link"
        class="header-link text-gray-900"
      >
        {{ $t("core.modules.account.login.link.signUp") }}
      </DsLink>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import logo from "@/assets/img/logo-small.png";

import { DsLink } from "@devsalsa/vue-core";

export default defineComponent({
  name: "UnauthenticatedNavbar",
  components: {
    DsLink,
  },
  computed: {
    logo() {
      return logo;
    },
    showRegisterLinks(): boolean {
      return this.$route.path.startsWith("/register");
    },
    showLoginLinks(): boolean {
      return this.$route.path.startsWith("/login");
    },
  },
});
</script>

<style lang="scss">
.unauthenticated-header {
  @apply flex items-center justify-between fixed z-10 top-0 h-16 xs:px-6 px-4 w-full;

  .header-link {
    @apply text-sm hover:underline no-underline;
  }
}
</style>
