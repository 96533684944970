import { useNotifierStore } from "@/store/NotifierStore";

export interface Notification {
  message: string;
  duration?: number;
  enableClose?: boolean;
  position?: string;
  overlay?: boolean;
}

export interface ToastNotification extends Notification {
  themeColor: string;
  duration: number;
  enableClose: boolean;
  position: string;
  overlay: boolean;
}

export interface StoreNotification extends ToastNotification {
  id: number;
}

export default class Notifier {
  static sendInfo(notification: Notification): number {
    const defaults = {
      themeColor: "blue",
      duration: 10,
      enableClose: true,
      position: "bottom-0 right-0",
      overlay: false,
    };
    return Notifier.storeNotification({ ...defaults, ...notification });
  }

  static sendSuccess(notification: Notification): number {
    const defaults = {
      themeColor: "green",
      duration: 10,
      enableClose: true,
      position: "bottom-0 right-0",
      overlay: false,
    };
    return Notifier.storeNotification({ ...defaults, ...notification });
  }

  static sendWarning(notification: Notification): number {
    const defaults = {
      themeColor: "yellow",
      duration: 10,
      enableClose: true,
      position: "bottom-0 right-0",
      overlay: false,
    };
    return Notifier.storeNotification({ ...defaults, ...notification });
  }

  static sendError(notification: Notification): number {
    const defaults = {
      themeColor: "red",
      duration: 10,
      enableClose: true,
      position: "bottom-0 right-0",
      overlay: false,
    };
    return Notifier.storeNotification({ ...defaults, ...notification });
  }

  private static storeNotification(notification: ToastNotification): number {
    const id = Math.floor(Math.random() * 1000000);
    useNotifierStore().add({
      id: id,
      ...notification,
    });
    return id;
  }

  static deleteNotification(id: number): void {
    useNotifierStore().delete(id);
  }
}
