import { createRouter, createWebHistory } from "vue-router";
import type {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import PageNotFoundRoutes from "@/modules/page-not-found/router";
import AccountRoutes from "@/core/modules/account/router";
import SettingsRoutes from "@/modules/settings/router";
import DashboardRoutes from "@/modules/dashboard/router";
import OnboardingRoutes from "@/modules/onboarding/router";
import ProjectRoutes from "@/modules/project/router";
import RuleRoutes from "@/modules/rule/router";
import SubscriptionRoutes from "@/modules/subscription/router";
import i18n from "@/i18n";
import { ErrorHandler } from "@/core/shared/helpers/Error/ErrorHandler";
import { LoaderHandler } from "@/core/shared/helpers/LoaderHandler";
import { ModalHandler } from "@/core/shared/helpers/Modal/ModalHandler";

const baseRoutes: RouteRecordRaw[] = PageNotFoundRoutes;
const routes: RouteRecordRaw[] = baseRoutes.concat(
  AccountRoutes,
  SettingsRoutes,
  DashboardRoutes,
  OnboardingRoutes,
  ProjectRoutes,
  RuleRoutes,
  SubscriptionRoutes,
);

const preloader = window.document.getElementById("preloader") as HTMLElement;

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return {
        ...savedPosition,
        behavior: "smooth",
      };
    } else if (
      to.name === from.name &&
      to.hash !== "" &&
      to.hash !== from.hash
    ) {
      return;
    }
    return {
      top: 0,
      left: 0,
      behavior: "smooth",
    };
  },
});

router.onError((error: unknown) => {
  console.log("VueJS router error handler", error);
  ErrorHandler.handle(error);
});
router.beforeEach(
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) => {
    if (preloader.classList.contains("hidden")) {
      LoaderHandler.enable();
    }
    window.document.title = `${
      import.meta.env.VITE_APP_TITLE
    } - ${i18n.global.t(<string>to.meta.title)}`;
    to.meta.from = from.name;
    next();
    // Closes all modals before route change.
    ModalHandler.closeAll();
  },
);
router.afterEach(() => {
  //Hide the preloader if not hidden yet.
  preloader.classList.add("hidden");
  LoaderHandler.disable();
});

export default router;
