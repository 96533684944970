import { defineStore } from "pinia";
import type { ChatMessageSocket } from "@/core/shared/helpers/Chat/ChatHandler";
import { getUnixTime } from "date-fns";

export interface ChatNotifications {
  unread_chats: number;
  chats: UnreadChatsMessages[];
}

export interface UnreadChatsMessages {
  chat_id: number;
  unread_messages: number;
}

export interface ChatState {
  messages: {
    [key: string]: ChatMessageSocket;
  };
  unreadChats: number;
  chats: UnreadChatsMessages[];
  presences: Presence[];
}

export type PresenceType = "active" | "inactive" | "undefined";
export interface Presence {
  id: number;
  presence: PresenceType;
}

export const useChatStore = defineStore("chat", {
  state: (): ChatState => ({
    messages: {},
    unreadChats: 0,
    chats: [],
    presences: [],
  }),
  actions: {
    setIncomeMessage(message: ChatMessageSocket): void {
      this.messages[message.chat_id] = {
        chat_id: message.chat_id,
        message: message.message,
        sequence_id: message.sequence_id,
        user_type: message.user_type,
        image: message.image,
        date_created: getUnixTime(new Date()),
      };
    },
    unsetMessage(message: ChatMessageSocket): void {
      delete this.messages[message.chat_id];
    },
    setUnreadChats(totalUnread: number): void {
      this.unreadChats = totalUnread;
    },
    setUnreadChatsMessages(chats: UnreadChatsMessages[]): void {
      this.chats = chats;
    },
    addRegisterPresence(id: number) {
      this.presences.push({
        id: id,
        presence: "undefined",
      });
    },
    registerPresence(id: number) {
      // Check if exists the id
      // else create record with undefined value
      const index = this.presences.findIndex(
        (currentPresence) => currentPresence.id === id,
      );
      if (index === -1) {
        this.addRegisterPresence(id);
      }
    },
    getPresence(id: number): PresenceType {
      const index = this.presences.findIndex(
        (currentPresence) => currentPresence.id === id,
      );
      if (index !== -1) {
        return this.presences[index].presence;
      }
      return "undefined";
    },
    setPresences(newPresences: Presence[]) {
      newPresences.forEach((newPresence) => {
        const index = this.presences.findIndex(
          (currentPresence) => currentPresence.id === newPresence.id,
        );
        if (index > -1) {
          this.presences[index].presence = newPresence.presence;
        } else {
          this.presences.push(newPresence);
        }
      });
    },
  },
});
