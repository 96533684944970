import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

export default [
  {
    path: "",
    beforeEnter: OnboardingGuard.withoutProject,
    children: [
      {
        path: "onboarding/project",
        name: "OnboardingProject",
        component: () =>
          import("@/modules/onboarding/views/OnboardingProject.vue"),
        meta: {
          title: "modules.onboarding.views.onboardingProject.meta.title",
          layout: "OnboardingLayout",
        },
      },
    ],
  },
];
