import { defineStore } from "pinia";

export interface ModalState {
  modals: ModalData[];
}

export interface ModalData {
  show: boolean;
  id: string;
}

export const useModalStore = defineStore("modal", {
  state: (): ModalState => ({
    modals: [] as ModalData[],
  }),
  actions: {
    beforeOpen(id: string): string {
      return id;
    },
    open(modalData: ModalData): void {
      const index = this.modals.findIndex(function (modal) {
        return modalData.id === modal.id;
      });
      //If index not found, add modal, if exist update modal
      if (index === -1) {
        this.modals.push(modalData);
      } else {
        this.modals[index] = modalData;
      }
    },
    beforeClose(id: string): string {
      return id;
    },
    close(id: string): void {
      const index = this.modals.findIndex(function (modal) {
        return modal.id === id;
      });
      //If index found, delete the modal
      if (index !== -1) {
        setTimeout(() => {
          this.modals.splice(index, 1);
        }, 5);
      }
    },
    closeAll(): void {
      this.modals = [];
    },
  },
});
