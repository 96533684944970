import DashboardGuard from "@/modules/dashboard/helpers/DashboardGuard";
import RuleGuard from "@/modules/rule/component/RuleGuard";
import ProjectGuard from "@/modules/project/components/ProjectGuard";

export default [
  {
    path: "/project/:projectId/rule",
    beforeEnter: DashboardGuard.withCompleteOnboarding,
    children: [
      {
        path: "create",
        name: "RuleCreation",
        component: () => import("@/modules/rule/views/RuleCreation.vue"),
        beforeEnter: ProjectGuard.belongsToUser,
        meta: {
          title: "modules.rule.views.ruleCreation.meta.title",
          layout: "ProjectLayout",
        },
      },
      {
        path: ":ruleId",
        name: "RuleEdition",
        component: () => import("@/modules/rule/views/RuleEdition.vue"),
        beforeEnter: RuleGuard.belongsToUser,
        meta: {
          title: "modules.rule.views.ruleEdition.meta.title",
          layout: "ProjectLayout",
        },
      },
    ],
  },
];
