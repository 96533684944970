{
  "common": {
    "form": {
      "input": {
        "characters": "Characters: {currentCharacters} / {characters}",
        "email": {
          "label": "Email address",
          "placeholder": "email{'@'}domain.tld",
          "validation": {
            "required": "The email address is required.",
            "invalid": "The email address is invalid."
          }
        },
        "password": {
          "label": "Password",
          "placeholder": "10 characters minimum",
          "validation": {
            "required": "The password is required.",
            "min": "The password must have at least 10 characters.",
            "format": "The password must be minimum 10 characters long to include a combination of at least 2 uppercase letters, 2 lowercase letters and 2 digits (white spaces not allowed)."
          }
        },
        "oldPassword": {
          "label": "Current password",
          "validation": {
            "required": "The current password is required."
          }
        },
        "newPassword": {
          "label": "New password",
          "placeholder": "10 characters minimum",
          "validation": {
            "required": "The new password is required.",
            "min": "The new password must have at least 10 characters.",
            "format": "The new password must be minimum 10 characters long to include a combination of at least 2 uppercase letters, 2 lowercase letters and 2 digits (white spaces not allowed)."
          }
        },
        "confirmPassword": {
          "label": "Confirm password",
          "validation": {
            "required": "The confirm password is required.",
            "bothMatch": "Passwords do not match"
          }
        },
        "firstName": {
          "label": "First name",
          "placeholder": "Your first name",
          "validation": {
            "required": "The first name is required."
          }
        },
        "lastName": {
          "label": "Last name",
          "placeholder": "Your last name",
          "validation": {
            "required": "The last name is required."
          }
        },
        "zip": {
          "label": "Zip code",
          "placeholder": "ZIP code",
          "validation": {
            "format": "The ZIP code is not valid.",
            "required": "The ZIP code is required.",
            "min": "The ZIP code must have at least {min} characters.",
            "max": "The ZIP code must have at most {max} characters."
          }
        },
        "phone": {
          "label": "Phone number",
          "placeholder": "Enter your phone number",
          "validation": {
            "required": "The phone number is required.",
            "regex": "Should have only numbers.",
            "min": "The minimum number of digits is {min}.",
            "max": "The maximum number of digits is {max}."
          }
        },
        "phonePrefix": {
          "label": "Phone prefix",
          "placeholder": "Select a phone prefix",
          "validation": {
            "required": "The phone prefix is required."
          }
        },
        "linkedIn": {
          "label": "LinkedIn (optional)",
          "placeholder": "https://www.linkedin.com/",
          "validation": {
            "format": "The URL should be a valid LinkedIn URL."
          }
        },
        "image": {
          "label": "Upload an image",
          "validation": {
            "required": "The logo is required.",
            "format": "The file format is not supported.",
            "size": "The file size is larger than the maximum size allowed."
          }
        },
        "policy": {
          "label": "I have read and accept the {0} and the {1}",
          "terms": "TOS",
          "privacy": "Privacy Policy",
          "validation": {
            "required": "Please accept the terms of service and the privacy policy."
          }
        },
        "cardNumber": {
          "label": "Card number",
          "required": "The card number is required."
        },
        "cardExpiry": {
          "label": "Expiration MM/YY",
          "required": "The expiration date is required."
        },
        "cardCvv": {
          "label": "CVV",
          "required": "The CVV is required."
        },
        "cardName": {
          "label": "Cardholder name",
          "required": "The cardholder name is required."
        },
        "cardNickname": {
          "label": "Card nickname (optional)",
          "placeholder": "Card nickname",
          "required": "The cardholder name is required."
        },
        "imageUpload": {
          "label": "Upload an image",
          "fileType": "PNG, JPG up to 50MB",
          "minimumResolution": "Minimum resolution {minWidth}×{minHeight} or higher.",
          "cropperMinimumResolution": "Resolution min {minWidth}×{minHeight} and max {maxWidth}×{maxHeight}.",
          "validation": {
            "required": "The image is required.",
            "format": "The file format is not supported.",
            "size": "The file size is larger than the maximum size allowed.",
            "minResolution": "The file resolution is too low ({minWidth}x{minHeight}).",
            "maxResolution": "The file resolution is too high ({maxWidth}x{maxHeight})."
          }
        },
        "videoUpload": {
          "label": "Choose a video",
          "fileType": "MP4, MOV, AVI up to 1GB",
          "aspectRatio": "16:9 or 9:16",
          "minimumResolution": "Minimum resolution 1920x1080 (HD)",
          "duration": "Duration 30 - 60 seconds",
          "validation": {
            "format": "The video format is not supported."
          }
        },
        "search": {
          "placeholder": "Search",
          "validation": {
            "required": "The search is required.",
            "max": "The search is too long."
          }
        }
      },
      "select": {
        "default": "Choose an option",
        "option": "Select an option"
      },
      "checkbox": {
        "validation": {
          "required": "You must select at least one option."
        }
      },
      "textarea": {
        "characters": "Characters: {currentCharacters} / {characters}"
      },
      "textEditor": {
        "characters": "Characters: {currentCharacters} / {characters}"
      },
      "submit": {
        "savedSuccess": "Saved Successfully"
      }
    },
    "button": {
      "upgrade": "Upgrade",
      "expand": "Expand",
      "clear": "Clear",
      "clearAll": "Clear all",
      "collapse": "Collapse",
      "markAllRead": "Mark all as read",
      "markRead": "Mark as read",
      "removeFromList": "Remove from list",
      "settings": "Settings",
      "showAll": "Show all",
      "previous": "Prev",
      "login": "Sign in",
      "continue": "Continue",
      "skip": "Skip",
      "send": "Send",
      "sending": "Sending...",
      "loading": "Loading...",
      "downloading": "Downloading...",
      "leaving": "Leaving...",
      "searching": "Searching...",
      "confirm": "Confirm",
      "resetPassword": "Reset password",
      "nextStep": "Next Step",
      "back": "Back",
      "cancel": "Cancel",
      "save": "Save",
      "confirmPay": "Confirm and Pay",
      "saveCard": "Save card",
      "saveChanges": "Save changes",
      "next": "Next",
      "close": "Close",
      "edit": "Edit",
      "yes": "Yes",
      "no": "No",
      "requestDemo": "Request a demo",
      "socialMedia": {
        "signIn": {
          "facebook": "Sign in with Facebook",
          "google": "Sign in with Google",
          "apple": "Sign in with Apple"
        },
        "signUp": {
          "facebook": "Sign up with Facebook",
          "google": "Sign up with Google",
          "apple": "Sign up with Apple"
        },
        "alt": {
          "facebook": "Sign in with Facebook",
          "google": "Sign in with Google",
          "apple": "Sign in with Apple"
        },
        "altSignup": {
          "facebook": "Sign up with Facebook",
          "google": "Sign up with Google",
          "apple": "Sign up with Apple"
        }
      },
      "update": "Update",
      "addCreditCard": "Add credit card",
      "showExample": "Show examples",
      "home": "Go back home",
      "apply": "Apply",
      "filters": "Filters",
      "email": "Email"
    },
    "error": {
      "global": {
        "internalServerError": "An error occurred (error code {id})",
        "connectivityError": "We encounter connectivity problems. Retrying...",
        "networkError": "A network error occurred when processing your request. Please retry or contact our support.",
        "offline": "It looks like you are offline. Please check your internet connection...",
        "maintenance": "{project} is undergoing maintenance, please check back later.",
        "upgrading": "{project} is being updated, please be patient...",
        "notAuthorized": "You have been logged out to protect your account, please login again.",
        "userSuspended": "There is an issue with your account, please contact our support team.",
        "googleLoginFailed": "An unexpected error happened when logging you in with your Google account",
        "facebookLoginFailed": "An unexpected error happened when logging you in with your Facebook account",
        "appleLoginFailed": "An unexpected error happened when logging you in with your Apple account",
        "addressVerificationFaileSubscriptionOved": "Address verification failed.",
        "endpointNotFound": "There was a global problem.",
        "emailNotSetup": "Email is missing required field.",
        "emailVerificationRequired": "Email verification is required. Please check your inbox.",
        "emailVerificationResendTooSoon": "Please wait a minute before requesting a new email confirmation.",
        "invalidEmailPasswordCombination": "The credentials you provided are incorrect.",
        "invalidInput": "Some information that you provided is missing or invalid.",
        "invalidEmailVerificationCode": "Verification code has expired.",
        "invalidPassword": "The password you provided is incorrect.",
        "invalidFileUpload": "No file uploaded or invalid file type.",
        "userExists": "The email address is already in use.",
        "userNotFound": "We can't find an account with this email address.",
        "nodeNotFound": "We can't find an ingest node.",
        "projectNotFound": "We can't find the project.",
        "invalidRule": "Please make sure all your traffic rules are properly defined."
      },
      "firebase": {
        "accountExists": "Your {project} account is already linked to your {existingProvider} account. Please sign in your {existingProvider} account so we can link your {newProvider} account too."
      },
      "validation": {
        "minStringChars": "The minimum number of characters required is {min}.",
        "maxStringChars": "The maximum number of characters allowed is {max}.",
        "minNumber": "The minimum number required is {min}.",
        "maxNumber": "The maximum number allowed is {max}.",
        "fileTooLarge": "The file is too large (maximum size {maxSize}) or an error occurred while uploading the file.",
        "imageInsufficientResolution": "The minimum resolution required is {requiredWidth}x{requiredHeight} but the following resolution has been detected: {detectedWidth}x{detectedHeight}.",
        "videoInsufficientResolution": "The minimum resolution required is {requiredWidth}x{requiredHeight} but the following resolution has been detected: {detectedWidth}x{detectedHeight}.",
        "notDurationRequired": "The duration should be between {min} and {max} seconds.",
        "numberTypeError": "You must specify a number."
      },
      "image": "Image error",
      "stripe": {
        "insufficientFunds": "The credit card has insufficient funds to cover this payment.",
        "authenticationRequired": "The payment requires authentication to proceed.",
        "incorrectNumber": "The card number is incorrect. Check the card’s number or use a different card.",
        "processingError": "An error occurred while processing the card. Try again later or with a different payment method.",
        "expiredCard": "The card has expired. Check the expiration date or use a different card.",
        "incorrectCvc": "The card’s security code is incorrect. Check the card’s security code or use a different card.",
        "invalidCvc": "The card’s security code is invalid. Check the card’s security code or use a different card.",
        "transactionNotAllowed": "The customer needs to contact their card issuer for more information.",
        "doNotHonor": "The customer needs to contact their card issuer for more information.",
        "genericDecline": "The customer needs to contact their card issuer for more information.",
        "defaultErrorMessage": "The card was declined for an unknown reason. Please contact our support."
      }
    },
    "system": {
      "version": "New version",
      "updateMessage": "NetIngest is ready to update, please press the Refresh page button below.",
      "refresh": "Refresh page"
    },
    "toast": {
      "information": "Information",
      "warning": "Warning",
      "error": "Error",
      "success": "Success"
    },
    "route": {
      "dashboard": {
        "meta": "Dashboard",
        "link": "Dashboard"
      },
      "profile": {
        "meta": "Profile",
        "link": "Profile"
      },
      "logout": {
        "link": "Log out"
      },
      "pageNotFound": {
        "meta": "Page not found"
      },
      "notifications": {
        "meta": "Notification"
      }
    },
    "flash": {
      "resendVerification": "We sent you a new verification link to your email.",
      "sendResetPassword": "We sent you an email to reset your password."
    },
    "datatable": {
      "showing": "Showing",
      "to": "to",
      "of": "of"
    },
    "placeholder": {
      "search": "Search"
    },
    "privacy": "Privacy Policy",
    "terms": "Terms & Conditions"
  },
  "core": {
    "shared": {
      "views": {
        "notAvailable": "N/A",
        "pageNotFound": {
          "h1": "Page Not Found",
          "url": "Please check the URL in the address bar and try again."
        }
      },
      "components": {
        "layout": {
          "footer": {
            "rights": "All rights reserved",
            "blog": "Blog",
            "academy": "Academy",
            "faq": "FAQ",
            "english": "English",
            "currency": "USD",
            "contact": "Contact support"
          },
          "navbar": {
            "dashboard": "Dashboard",
            "account": "Account",
            "subscription": "Subscription",
            "paymentMethod": "Payment method",
            "billing": "Billing",
            "contact": "Contact support"
          },
          "sidebar": {
            "link": {
              "profile": "Profile"
            }
          },
          "progressBar": {
            "uploading": "Uploading..."
          }
        },
        "pagination": {
          "paginationDeets": {
            "showing": "Showing",
            "pagination": "{0} {1} to {2} of {3} {4}"
          }
        },
        "videoUpload": {
          "status": {
            "processing": "Processing...",
            "failed": "We were not able to process your video. Please try it again or upload another video."
          }
        },
        "imageCropper": {
          "desktopLabel": "Scroll to Zoom",
          "mobileLabel": "Pinch to zoom"
        },
        "calendar": {
          "options": {
            "1": "Today",
            "2": "This week",
            "3": "Last week",
            "4": "This month",
            "5": "Last month",
            "6": "This year"
          },
          "month": {
            "0": "January",
            "1": "February",
            "2": "March",
            "3": "April",
            "4": "May",
            "5": "June",
            "6": "July",
            "7": "August",
            "8": "September",
            "9": "October",
            "10": "November",
            "11": "December"
          },
          "day": {
            "1": "Sun",
            "2": "Mon",
            "3": "Tue",
            "4": "Wed",
            "5": "Thu",
            "6": "Fri",
            "7": "Sat"
          },
          "buttons": {
            "cancel": "Cancel",
            "confirmation": "Ok"
          }
        },
        "slider": {
          "more": "Show details",
          "less": "Collapse"
        },
        "contactSupport": {
          "title": "Contact support",
          "subject": "Subject",
          "characters": "Characters: {currentCharacters} / {characters}",
          "buttons": {
            "cancel": "Cancel",
            "send": "Send",
            "ok": "Ok"
          },
          "form": {
            "details": {
              "label": "Details",
              "placeholder": "Please describe your problem with as much details as possible!"
            },
            "name": {
              "label": "Enter your name",
              "validation": {
                "required": "The name is required",
                "min": "The minimum characters required is {nb}"
              }
            },
            "email": {
              "label": "Email address",
              "placeholder": "name{'@'}example.com",
              "validation": {
                "required": "The email is required"
              }
            },
            "subjectValidation": {
              "required": "The subject is required",
              "max": "The maximum characters allowed is {nb}",
              "min": "The minimum characters required is {nb}"
            },
            "detailsValidation": {
              "required": "Details field is required",
              "max": "The maximum characters allowed is {nb}",
              "min": "The minimum characters required is {nb}"
            }
          },
          "submitted": {
            "title": "Thank you, your ticket has been sent",
            "message": "Our support specialist will contact you shortly."
          }
        },
        "content": {
          "contentPreviewModal": {
            "paging": "{0} of {1}"
          }
        },
        "comboboxTags": {
          "input": {
            "placeholder": "Search...",
            "validation": {
              "max": "The requirement may not be longer than { characters } characters.",
              "min": "The requirement may not be less than { characters } characters."
            }
          }
        },
        "video": {
          "videoCloud": {
            "unsupported": "The video format is unsupported or the video can't be played."
          }
        }
      },
      "helpers": {
        "units": {
          "time": {
            "seconds": " {nb}s",
            "minutes": " {nb}m",
            "hours": " {nb}h",
            "days": " {nb}d",
            "months": " {nb}mo",
            "years": " {nb}yr",
            "fewSeconds": "few seconds",
            "endWord": {
              "left": "{text} left",
              "late": "{text} late"
            }
          }
        }
      }
    },
    "modules": {
      "account": {
        "register": {
          "meta": "Sign up",
          "title": {
            "text1": "Sign up",
            "text2": " as a Brand"
          },
          "form": {
            "firstName": {
              "label": "First name",
              "placeholder": "Enter your first name",
              "validation": {
                "required": "The first name is required."
              }
            },
            "lastName": {
              "label": "Last name",
              "placeholder": "Enter your last name",
              "validation": {
                "required": "The last name is required."
              }
            },
            "email": {
              "label": "Email address",
              "placeholder": "name{'@'}example.com"
            },
            "password": {
              "label": "Password",
              "placeholder": "10 characters minimum"
            },
            "button": {
              "label": "Continue"
            }
          },
          "links": {
            "account": "Already have an account?",
            "signIn": "Sign In"
          },
          "legend": {
            "text": "By signing up, you agree to the {0} and {1}"
          }
        },
        "login": {
          "meta": "Sign in",
          "h1": "Sign in",
          "text": {
            "account": "Don't have an account?"
          },
          "link": {
            "forgot": "Forgot password?",
            "signUp": "Sign up here"
          },
          "verifyCode": {
            "message": "You have to be logged in to verify your account."
          }
        },
        "logout": {
          "meta": "Logout",
          "h1": {
            "loggingOut": "Logging you out...",
            "loggedOut": "You have been logged out!"
          },
          "p": {
            "loggingOut": "We are logging you out from the system, please be patient...",
            "loggedOut": "You have been successfully logged out from the system."
          }
        },
        "forgotPassword": {
          "meta": "Forgot Password?",
          "h1": "Forgot password?",
          "button": {
            "label": "Reset Password",
            "loading": "Sending reset link..."
          },
          "text": "Already have a {project} account?",
          "link": {
            "login": "Sign in"
          }
        },
        "resetPassword": {
          "meta": "Reset Password",
          "h1": {
            "reset": "Reset your password",
            "updated": "Password reset successful"
          },
          "p": {
            "updated": "Your password has been successfully reset. Please click on the button below to login."
          },
          "label": "New Password",
          "text": "Already have a {project} account?",
          "link": "Sign in"
        },
        "confirmEmail": {
          "meta": "Confirm your email",
          "h1": "Let's confirm your email"
        },
        "impersonate": {
          "meta": "Impersonate",
          "h1": {
            "impersonating": "Logging you in...",
            "impersonated": "You are now logged in as {name}"
          },
          "p": {
            "impersonating": "We are logging you into the system, please be patient...",
            "impersonated": "You have been successfully logged to the system."
          }
        },
        "verifyEmail": {
          "meta": "Verify your email address",
          "h1": {
            "title": "Please check your inbox",
            "wrongEmail": "Wrong email address?"
          },
          "p": {
            "sent": "We sent an email to {0}. In order to continue the sign-up process, please click the verification link in that email.",
            "spam": "If you did not receive the confirmation email, please check your spam folder and/or verify that you entered the correct email address above.",
            "mistypedEmail": "If you mistyped your email address, you can simply go through the register page again. Do you want to continue?"
          },
          "flash": {
            "resend": "We sent you a new verification link to your email.",
            "email": "We sent you a verification link to your email."
          },
          "button": {
            "emailNotReceived": "Didn't receive an email?",
            "wrongEmail": "Wrong email?",
            "waiting": "Resend in {time}s...",
            "cancel": "Cancel",
            "continue": "Continue"
          },
          "updateEmail": {
            "p": {
              "sent": "We sent an email to {0}. In order to continue the update process, please click the verification link in that email."
            }
          }
        },
        "verifyEmailCode": {
          "meta": "Verifying your email address",
          "h1": {
            "verifying": "Verifying...",
            "verified": "Email address verified!",
            "notVerified": "Email address not verified"
          },
          "h3": {
            "verifying": "We are verifying your email address, almost there...",
            "verified": "Your email address has been successfully verified."
          },
          "p": {
            "causes": "An error occurred when verifying your email address. This could occur because the code is expired or is incorrect.",
            "request": "You can request another verification code, which will be sent to your email address {0}.",
            "restart": "If you mistyped your email address, you can simply go through the register page again. Do you want to continue?"
          },
          "button": {
            "emailNotReceived": "Resend verification",
            "waiting": "Resend in {time}s...",
            "wrongEmail": "Wrong email?",
            "cancel": "Cancel",
            "continue": "Continue"
          }
        }
      }
    }
  },
  "modules": {
    "dashboard": {
      "views": {
        "overviewDashboard": {
          "meta": {
            "title": "Overview dashboard"
          },
          "h1": "Projects",
          "button": {
            "create": "Create new project",
            "see": "Go to project"
          },
          "subscription": {
            "title": "Included in your {0}",
            "description": "Your {0} allows you to use {1} requests per month, if you reach this limit before the end of the month Overage packages can be charged automatically to ensure uninterrupted service."
          },
          "overage": {
            "title": "Overage",
            "description": "Requests in your overage package roll over to the next month, requests in your month plan do not."
          },
          "projectsList": {
            "projectDetails": {
              "button": {
                "view": "View",
                "delete": "Delete"
              },
              "deletion": {
                "title": "Delete project",
                "description": "Are you sure you want to delete the selected project {0}?",
                "flash": {
                  "success": "The project has been successfully deleted.",
                  "error": "An error occurred when deleting the project."
                }
              }
            }
          }
        }
      }
    },
    "onboarding": {
      "views": {
        "onboardingProject": {
          "meta": {
            "title": "Let's get started"
          },
          "title": "Let's get started",
          "text": "First thing first, provide the name of the website/application you'd like to protect.",
          "form": {
            "input": {
              "projectName": {
                "label": "Website/application name",
                "placeholder": "Enter the name of the website/application"
              }
            },
            "button": "Continue"
          }
        }
      }
    },
    "project": {
      "menu": {
        "overview": "Overview",
        "traffic": "Traffic",
        "rules": "Rules",
        "ipAddresses": "IP addresses",
        "settings": "Settings",
        "installation": "Installation"
      },
      "views": {
        "projectOverview": {
          "meta": {
            "title": "Project dashboard"
          },
          "h2": "Overview",
          "dimension": {
            "allowHits": "Requests allowed",
            "denyHits": "Requests denied",
            "captchaHits": "Captcha challenges displayed",
            "captchaResolvedHits": "Captcha challenges resolved",
            "throttleHits": "Requests throttled",
            "unknownHits": "Unknown requests"
          },
          "link": {
            "seeRequests": "See requests"
          },
          "form": {
            "dateRange": "{start} to {end}",
            "granularity": {
              "hour": "Hour",
              "day": "Day",
              "week": "Week",
              "month": "Month",
              "year": "Year"
            },
            "button": {
              "calendar": "Select dates"
            }
          }
        },
        "projectInstallation": {
          "meta": {
            "title": "Integration installation"
          },
          "h2": "Installation",
          "explanations": "Here are the instructions to integrate Netingest:",
          "tabs": {
            "php": {
              "label": "PHP",
              "steps": {
                "composer": "First, install the Netingest PHP SDK:",
                "code": "Then init the PHP SDK:"
              }
            },
            "wordpress": {
              "label": "WordPress",
              "steps": {
                "download": {
                  "description": "First, download the Netingest WordPress plugin:",
                  "button": "Download"
                },
                "install": {
                  "description": "Then install the WordPress plugin, find the Netingest item in the WordPress main admin menu and click on it:",
                  "image": "WordPress menu where you can see the Netingest WordPress plugin"
                },
                "dsn": "To finish, provide the following DSN to start protecting your website:"
              },
              "copy": {
                "button": "Copy",
                "composer": "The Composer command has been copied.",
                "php": "The PHP source code has been copied.",
                "dsn": "The DSN has been copied."
              }
            }
          }
        },
        "projectCreation": {
          "meta": {
            "title": "Project creation"
          },
          "title": "Need to protect a new website?",
          "text": "Let's create it: provide the name of the website/application you'd like to protect.",
          "form": {
            "input": {
              "projectName": {
                "label": "Website/application name",
                "placeholder": "Enter the name of the website/application",
                "validation": {
                  "required": "The name of the website/application is required."
                }
              }
            },
            "button": "Continue"
          }
        },
        "projectSettings": {
          "meta": {
            "title": "Project settings"
          },
          "h2": "Settings",
          "section": {
            "projectName": {
              "title": "Project information",
              "label": "Project name",
              "form": {
                "name": {
                  "label": "Name",
                  "placeholder": "Enter the name of the project",
                  "validation": {
                    "required": "The name of the project is required.",
                    "minStringChars": "The minimum number of characters required is 3.",
                    "maxStringChars": "The maximum number of characters allowed is 128."
                  }
                }
              },
              "flash": {
                "success": "The project name has been successfully updated."
              }
            },
            "captcha": {
              "title": "Captcha",
              "label": "Captcha library to use",
              "type": {
                "classic": "Classic captcha",
                "recaptcha": "reCaptcha"
              },
              "form": {
                "captchaType": {
                  "validation": {
                    "required": "The type of captcha to use is required."
                  }
                },
                "recaptchaSiteKey": {
                  "label": "Site key",
                  "placeholder": "Enter the reCaptcha site key",
                  "validation": {
                    "required": "The reCaptcha site key is required.",
                    "minStringChars": "The minimum number of characters required is 10.",
                    "maxStringChars": "The maximum number of characters allowed is 255."
                  }
                },
                "recaptchaSecretKey": {
                  "label": "Secret key",
                  "placeholder": "Enter the reCaptcha secret key",
                  "validation": {
                    "required": "The reCaptcha secret key is required.",
                    "minStringChars": "The minimum number of characters required is 10.",
                    "maxStringChars": "The maximum number of characters allowed is 255."
                  }
                }
              },
              "flash": {
                "success": "The captcha settings have been successfully updated."
              }
            }
          },
          "footerForm": {
            "button": {
              "cancel": "Cancel",
              "save": "Save changes"
            }
          }
        },
        "projectTraffic": {
          "meta": {
            "title": "Traffic"
          },
          "h2": "Traffic",
          "tabs": {
            "top": {
              "label": "Top traffic",
              "noTraffic": "No traffic found."
            },
            "browser": {
              "label": "Traffic browser"
            }
          },
          "form": {
            "baseQuery": {
              "label": "Filter the traffic matching the following conditions"
            },
            "dateRange": "{start} to {end}",
            "period": {
              "fiveMinutes": "5 min",
              "fifteenMinutes": "15 min",
              "thirtyMinutes": "30 min",
              "oneHour": "1 hour",
              "sixHours": "6 hours",
              "twelveHours": "12 hours",
              "twentyFourHours": "24 hours"
            },
            "select": {
              "period": {
                "label": "In the last"
              }
            },
            "button": {
              "search": "Search traffic",
              "create": "Create new rule"
            }
          },
          "columns": {
            "date": "Date",
            "ip": "IP",
            "action": "Action",
            "actionSource": "Action source",
            "rule": "Rule",
            "host": "Host",
            "isp": "ISP",
            "city": "City",
            "region": "Region",
            "country": "Country",
            "continent": "Continent",
            "location": "Location",
            "mobile": "Mobile",
            "proxy": "Proxy",
            "reverseLookup": "Reverse lookup",
            "requestMethod": "Request method",
            "requestUri": "Request URI",
            "userAgent": "User agent",
            "userAgentBrowser": "User agent browser",
            "userAgentBrowserVersion": "User agent browser version",
            "userAgentDevice": "User agent device",
            "userAgentDetected": "User agent detected",
            "userAgentOs": "User agent OS"
          },
          "value": {
            "action": {
              "allow": "Allow",
              "captcha": "Captcha",
              "deny": "Block",
              "throttle": "Throttle"
            },
            "reason": {
              "new_ip": "New IP",
              "rule": "Rule",
              "whitelisted": "Whitelisted",
              "manually_banned": "Manual ban"
            }
          },
          "datatable": {
            "noRecords": "No requests yet.",
            "legend": "requests"
          },
          "top": {
            "ip": "IP",
            "host": "Host",
            "isp": "ISP",
            "ipCity": "City",
            "ipRegion": "Region",
            "ipCountry": "Country",
            "ipContinent": "Continent",
            "location": "Location",
            "ipMobile": "Mobile",
            "ipProxy": "Proxy",
            "ipHosting": "Hosting",
            "ipReverseLookup": "Reverse lookup",
            "requestMethod": "Request method",
            "requestUri": "Request URI",
            "userAgent": "User agent",
            "userAgentBrowser": "User agent browser",
            "userAgentBrowserVersion": "User agent browser version",
            "userAgentDevice": "User agent device",
            "userAgentIsDetected": "User agent detected",
            "userAgentOs": "User agent OS"
          }
        },
        "projectRules": {
          "meta": {
            "title": "Rules"
          },
          "h2": "Rules",
          "button": {
            "create": "Create new rule",
            "ruleset": "Import ruleset"
          },
          "noRules": "No rules yet.",
          "ruleDetails": {
            "badge": {
              "historical": "Historical",
              "ruleset": "Ruleset"
            },
            "tooltip": {
              "historical": "Historical",
              "ruleset": "A ruleset is a set of advanced rules that are automatically applied by our platform to protect any website/application."
            },
            "action": {
              "allow": "allow",
              "captcha": "captcha",
              "deny": "block",
              "throttle": "throttle"
            },
            "button": {
              "edit": "Edit",
              "clone": "Clone",
              "delete": "Delete"
            },
            "deletion": {
              "rule": {
                "title": "Delete rule",
                "description": "Are you sure you want to delete the selected rule {0}?",
                "flash": {
                  "success": "The rule has been successfully deleted.",
                  "error": "An error occurred when deleting the rule."
                }
              },
              "ruleset": {
                "title": "Delete ruleset",
                "description": "Are you sure you want to delete the selected ruleset {0}?",
                "flash": {
                  "success": "The ruleset has been successfully deleted.",
                  "error": "An error occurred when deleting the ruleset."
                }
              }
            },
            "dimension": {
              "cachedHits": "Cached requests",
              "matchedHits": "Matched requests"
            }
          },
          "rulesetModal": {
            "title": "Import a ruleset"
          },
          "rulesetDetails": {
            "button": {
              "import": "Import"
            },
            "flash": {
              "success": "The ruleset has been successfully imported.",
              "error": "An error occurred when importing the ruleset."
            }
          },
          "rulesetsList": {
            "noRulesets": "No rulesets yet."
          }
        },
        "projectIpAddresses": {
          "meta": {
            "title": "IP addresses"
          },
          "h2": "IP addresses",
          "ipStatusCheck": {
            "whitelist": {
              "whitelisted": "The IP is whitelisted.",
              "notWhitelisted": "The IP is not whitelisted.",
              "button": {
                "whitelist": "Whitelist",
                "unWhitelist": "Remove from whitelist"
              },
              "flash": {
                "success": {
                  "unWhitelisted": "The IP has been successfully removed from the whitelist.",
                  "whitelisted": "The IP has been successfully whitelisted."
                }
              }
            },
            "ban": {
              "banned": "The IP has been manually banned.",
              "allowed": "The IP is not banned.",
              "button": {
                "ban": "Manually ban",
                "unban": "Unban"
              },
              "flash": {
                "success": {
                  "unbanned": "The IP has been successfully unbanned.",
                  "banned": "The IP has been successfully banned."
                }
              }
            }
          },
          "ipManagement": {
            "form": {
              "ips": {
                "placeholder": "Enter a list of IPs (one IP per row)",
                "validation": {
                  "required": "At least one IP is required."
                }
              },
              "timeout": {
                "placeholder": "# of seconds",
                "suffix": "seconds"
              }
            },
            "button": {
              "whitelist": "Whitelist IPs",
              "ban": "Manually ban IPs"
            },
            "flash": {
              "success": {
                "banned": "The IPs have been successfully banned.",
                "whitelisted": "The IPs have been successfully whitelisted."
              }
            }
          }
        }
      }
    },
    "rule": {
      "visualFilter": {
        "dimension": {
          "host": "Host",
          "ip": "IP",
          "ipCity": "City",
          "ipContinent": "Continent",
          "ipCountry": "Country",
          "ipIsp": "ISP",
          "ipLatitude": "Latitude",
          "ipLongitude": "Longitude",
          "ipMobile": "Mobile",
          "ipRegion": "Region",
          "ipReverseLookup": "IP reverse lookup",
          "ipProxy": "Proxy",
          "ipHosting": "Hosting",
          "ipStatus": "Action",
          "ipStatusReason": "Action source",
          "ipStatusReasonValue": "Rule",
          "projectId": "Project ID",
          "requestMethod": "Request method",
          "requestUri": "Request URI",
          "tags": "Tag(s)",
          "timestamp": "Timestamp",
          "traceId": "Trace ID",
          "userAgent": "User agent",
          "userAgentBrowser": "Browser name",
          "userAgentBrowserVersion": "Browser version",
          "userAgentDevice": "Device",
          "userAgentIsDetected": "User agent detected",
          "userAgentOs": "OS",
          "headers": "Headers"
        },
        "method": {
          "equals": "equals to",
          "notEquals": "is not equal to",
          "contains": "contains",
          "notContains": "doesn't contain",
          "startsWith": "starts with",
          "notStartsWith": "doesn't start with",
          "endsWith": "ends with",
          "notEndsWith": "doesn't end with",
          "greaterThanOrEqualTo": "greater or equals to",
          "lessThanOrEqualTo": "less or equals to"
        },
        "placeholder": {
          "dimension": "Select a dimension",
          "operator": "Select an operator",
          "value": {
            "text": "Enter a value",
            "select": "Select a value"
          }
        },
        "boolean": {
          "true": "true",
          "false": "false"
        },
        "filter": {
          "and": "AND",
          "or": "OR"
        }
      },
      "views": {
        "ruleCreation": {
          "meta": {
            "title": "Rule creation"
          },
          "h2": "Create new rule",
          "form": {
            "name": {
              "label": "Name",
              "placeholder": "Enter the rule name"
            },
            "action": {
              "label": "Take action to",
              "placeholder": "Select the action",
              "options": {
                "allow": "Allow",
                "deny": "Block",
                "throttle": "Throttle",
                "captcha": "Captcha"
              }
            },
            "actionTimeout": {
              "label": "and apply for",
              "placeholder": "Enter the action timeout",
              "suffix": "seconds"
            },
            "baseQuery": {
              "label": "When the traffic matches the following conditions"
            },
            "historicalQuery": {
              "label": "and the traffic matches the following conditions in the past"
            },
            "minimumHistoricalHits": {
              "label": "for at least",
              "placeholder": "Enter the number of requests",
              "suffix": "times"
            },
            "historicalSeconds": {
              "label": "in the last",
              "placeholder": "Enter the number of seconds",
              "suffix": "seconds"
            },
            "stopProcessing": {
              "label": "Stop processing rules after match"
            },
            "button": {
              "create": "Create",
              "addHistorical": "Add historical filters"
            }
          },
          "flash": {
            "success": "The rule has been created successfully."
          }
        },
        "ruleEdition": {
          "meta": {
            "title": "Rule edition"
          },
          "h2": "Rule {name}",
          "form": {
            "name": {
              "label": "Name",
              "placeholder": "Enter the rule name"
            },
            "action": {
              "label": "Take action to",
              "placeholder": "Select the action",
              "options": {
                "allow": "Allow",
                "deny": "Block",
                "throttle": "Throttle",
                "captcha": "Captcha"
              }
            },
            "actionTimeout": {
              "label": "and apply for",
              "placeholder": "Enter the action timeout",
              "suffix": "seconds"
            },
            "baseQuery": {
              "label": "When the traffic matches the following conditions"
            },
            "historicalQuery": {
              "label": "and the traffic matches the following conditions in the past"
            },
            "minimumHistoricalHits": {
              "label": "for at least",
              "placeholder": "Enter the number of requests",
              "suffix": "times"
            },
            "historicalSeconds": {
              "label": "in the last",
              "placeholder": "Enter the number of seconds",
              "suffix": "seconds"
            },
            "stopProcessing": {
              "label": "Stop processing rules after match"
            },
            "button": {
              "save": "Save",
              "addHistorical": "Add historical filters"
            }
          },
          "flash": {
            "success": "The rule has been edited successfully."
          }
        }
      }
    },
    "settings": {
      "menu": {
        "since": "Client since {signUpDate}",
        "account": "Account",
        "subscription": "Subscription",
        "paymentMethod": "Payment method",
        "billing": "Billing",
        "contact": "Contact"
      },
      "views": {
        "accountOverview": {
          "meta": {
            "title": "Account"
          },
          "h1": "Account",
          "section": {
            "personalInformation": {
              "title": "Personal information",
              "label": "Your full name",
              "form": {
                "firstName": {
                  "label": "First name",
                  "placeholder": "Enter your first name",
                  "validation": {
                    "required": "Your first name is required.",
                    "minStringChars": "The minimum number of characters required is 3.",
                    "maxStringChars": "The maximum number of characters allowed is 128."
                  }
                },
                "lastName": {
                  "label": "Last name",
                  "placeholder": "Enter your last name",
                  "validation": {
                    "required": "Your last name is required.",
                    "minStringChars": "The minimum number of characters required is 3.",
                    "maxStringChars": "The maximum number of characters allowed is 128."
                  }
                }
              },
              "flash": {
                "success": "Your personal information has been updated successfully."
              }
            },
            "emailAddress": {
              "title": "Email address",
              "label": "Your email address",
              "form": {
                "email": {
                  "label": "Email address",
                  "placeholder": "Enter your email address",
                  "validation": {
                    "required": "Your email address is required.",
                    "email": "Your email address is invalid."
                  }
                },
                "password": {
                  "label": "Current password",
                  "placeholder": "Enter your current password",
                  "validation": {
                    "required": "Your current password is required."
                  }
                }
              },
              "flash": {
                "success": "Your email address has been updated successfully."
              }
            },
            "password": {
              "title": "Password",
              "label": "Your password",
              "form": {
                "oldPassword": {
                  "label": "Current password",
                  "placeholder": "Enter your current password",
                  "validation": {
                    "required": "Your email address is required.",
                    "email": "Your email address is invalid."
                  }
                },
                "newPassword": {
                  "label": "New password",
                  "placeholder": "10 characters minimum",
                  "validation": {
                    "required": "The new password is required.",
                    "min": "The new password must have at least 10 characters.",
                    "max": "The new password must have at maximum 32 characters."
                  }
                },
                "confirmPassword": {
                  "label": "Confirm new password",
                  "placeholder": "Confirm your new password",
                  "validation": {
                    "required": "The confirm password is required.",
                    "bothMatch": "The new password and its confirmation do not match."
                  }
                }
              },
              "flash": {
                "success": "Your password has been updated successfully."
              }
            }
          }
        },
        "billingOverview": {
          "meta": {
            "title": "Billing"
          },
          "title": "Billing",
          "buttonOpenCalendar": "mm/dd/yyyy - mm/dd/yyyy",
          "dateRange": "{start} to {end}",
          "sidebarText": "Billing",
          "refunded": "Refunded",
          "partiallyRefunded": "Partially refunded",
          "search": {
            "placeholder": "Transaction description"
          },
          "select": {
            "dateRange": {
              "placeholder": "All time",
              "options": {
                "today": "Today",
                "thisWeek": "This week",
                "lastWeek": "Last week",
                "thisMonth": "This month",
                "lastMont": "Last month",
                "thisYear": "This year"
              }
            },
            "paymentMethod": {
              "placeholder": "All payment methods",
              "options": {
                "creditCard": "Credit card",
                "wireTransfer": "Wire transfer"
              }
            }
          },
          "tabs": {
            "billingHistory": {
              "label": "Billing history",
              "paymentMethod": "{ccProvider} ending in {last4} ",
              "columns": {
                "date": "Date",
                "desc": "Description",
                "operation": "Operation",
                "method": "Payment method",
                "total": "Total",
                "pdf": "PDF",
                "credit_card": "Credit card",
                "debit_card": "Debit card",
                "wire_transfer": "Wire transfer"
              },
              "transactions": "transaction | transactions",
              "noData": "No transactions found.",
              "operation": {
                "credit": "Credit",
                "debit": "Debit"
              },
              "buttonExport": "Get statement"
            },
            "billingInfo": {
              "label": "Billing info",
              "card": {
                "title": "Billing information",
                "summary": {
                  "fullName": "Full name",
                  "companyName": "Company name",
                  "country": "Country",
                  "addressLine1": "Address line 1",
                  "city": "City",
                  "state": "State",
                  "zipCode": "ZIP code",
                  "taxId": "Tax ID",
                  "notProvided": "Not provided"
                },
                "form": {
                  "input": {
                    "name": {
                      "label": "Full name",
                      "validation": {
                        "required": "The name is required."
                      }
                    },
                    "companyName": {
                      "label": "Company name",
                      "validation": {
                        "required": "The company name is required."
                      }
                    },
                    "street": {
                      "label": "Address line 1",
                      "validation": {
                        "required": "The Address line 1 is required."
                      }
                    },
                    "street2": {
                      "label": "Address line 2"
                    },
                    "city": {
                      "label": "City/Town",
                      "validation": {
                        "required": "The city or town is required."
                      }
                    },
                    "taxId": {
                      "label": "Tax ID",
                      "validation": {
                        "number": "The TaxID must be a number."
                      }
                    }
                  },
                  "select": {
                    "state": {
                      "label": "State",
                      "placeholder": "Select a state",
                      "validation": {
                        "required": "The state is required."
                      }
                    },
                    "country": {
                      "label": "Country",
                      "placeholder": "Select a country",
                      "validation": {
                        "required": "The country is required."
                      }
                    }
                  },
                  "flash": {
                    "success": "Your billing info have been saved."
                  }
                }
              }
            }
          },
          "flash": {
            "message": "Your billing report will be emailed to you in a few minutes."
          }
        },
        "paymentMethod": {
          "meta": {
            "title": "Payment method"
          },
          "h1": "Payment method",
          "payment": {
            "payDirectly": "Pay easily with your credit or debit card.",
            "addCreditCard": "Add payment method"
          },
          "cards": {
            "title": "Ending in ••••",
            "subtitle": "Expires {month}/{year}",
            "default": "Make default",
            "defaultCard": "Default",
            "current": "Current",
            "issue": "Issue",
            "contextMenu": {
              "update": "Update"
            }
          }
        },
        "paymentMethodSetupCancellation": {
          "meta": {
            "title": "Payment method update cancelled"
          },
          "h1": "Payment method update cancelled",
          "subtitle": "The payment method update has been canceled. You can go back to the payment method management page."
        },
        "paymentMethodSetupSuccess": {
          "meta": {
            "title": "Payment method update"
          },
          "paymentMethodSetupLoader": {
            "h1": "Updating your payment method...",
            "h3": "We are updating your payment method. Please be patient, it could take a few minutes."
          },
          "paymentMethodSetupFailed": {
            "h1": "Payment method update failed",
            "p": {
              "issue": "We're sorry, but there was an issue updating your payment method.",
              "advice": "Please double-check the payment information provided and try again. If the problem persists, we recommend contacting your bank or financial institution to ensure there are no restrictions on your card/account that may be preventing the transaction."
            },
            "button": {
              "retry": "Retry"
            }
          },
          "paymentMethodSetupConfirmed": {
            "h1": "Payment method updated",
            "h3": "Your payment method has been updated successfully."
          }
        }
      }
    },
    "subscription": {
      "views": {
        "subscriptionOverview": {
          "meta": {
            "title": "Subscription"
          },
          "h1": {
            "setup": "Select a pricing plan",
            "manage": "Subscription"
          },
          "downgraded": "After your current plan expires, your account will switch to the free subscription.",
          "plan": {
            "current": "Current",
            "downgraded": "Plan ends on {date}",
            "monthly": "/Per Month",
            "select": "Select plan",
            "downgrade": "Downgrade plan",
            "upgrade": "Upgrade plan",
            "cancel": "Cancel plan",
            "requests": "{requests} requests"
          }
        },
        "subscriptionSetupCancellation": {
          "meta": {
            "title": "Subscription setup cancelled"
          },
          "h1": "Subscription setup cancelled",
          "subtitle": "The subscription setup has been canceled. You can go back to the pricing plans."
        },
        "subscriptionSetupSuccess": {
          "meta": {
            "title": "Subscription setup successful"
          },
          "subscriptionSetupLoader": {
            "h1": "Processing your payment...",
            "h3": "We are processing your payment. Please be patient, it could take a few minutes."
          },
          "subscriptionPaymentFailed": {
            "h1": "Payment failed",
            "p": {
              "issue": "We're sorry, but there was an issue processing your payment for the subscription.",
              "advice": "Please double-check the payment information provided and try again. If the problem persists, we recommend contacting your bank or financial institution to ensure there are no restrictions on your card/account that may be preventing the transaction."
            },
            "button": {
              "retry": "Retry"
            }
          },
          "subscriptionPaymentConfirmed": {
            "h1": "Payment successful...",
            "h3": "Your payment has been processed. Your subscription has been created successfully."
          }
        }
      }
    }
  }
}
