<template>
  <transition name="fade" mode="out-in">
    <component :is="layout" v-if="layout" />
  </transition>
  <NavigationLoader />
  <DsToastNotifier />
  <AppNotifier v-if="showAppNotifierOnlyOnDesktop" />
  <VersionManagerNotifier />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { DsToastNotifier } from "@devsalsa/vue-core";

import AccountLayout from "@/core/modules/account/components/AccountLayout.vue";
import AuthenticatedLayout from "@/core/shared/components/Layout/AuthenticatedLayout.vue";
import NavigationLoader from "@/core/shared/components/Navigation/NavigationLoader.vue";
import AppNotifier from "@/core/shared/components/Toast/AppNotifier.vue";
import VersionManagerNotifier from "@/core/shared/components/Toast/VersionManagerNotifier.vue";

import OnboardingLayout from "@/modules/onboarding/components/OnboardingLayout.vue";
import PageNotFoundLayout from "@/modules/page-not-found/components/PageNotFoundLayout.vue";
import ProjectCreationLayout from "@/modules/project/components/ProjectCreationLayout.vue";
import ProjectLayout from "@/modules/project/components/ProjectLayout.vue";
import PaymentMethodSetupLayout from "@/modules/settings/components/payment-method/setup/PaymentMethodSetupLayout.vue";
import SettingsLayout from "@/modules/settings/components/SettingsLayout.vue";
import SubscriptionSetupLayout from "@/modules/subscription/components/setup/SubscriptionSetupLayout.vue";
import SubscriptionLayout from "@/modules/subscription/components/SubscriptionLayout.vue";

import Browser from "@/core/shared/helpers/Browser";

export default defineComponent({
  components: {
    AccountLayout,
    AuthenticatedLayout,
    OnboardingLayout,
    PageNotFoundLayout,
    SettingsLayout,
    ProjectLayout,
    ProjectCreationLayout,
    SubscriptionSetupLayout,
    SubscriptionLayout,
    PaymentMethodSetupLayout,
    DsToastNotifier,
    AppNotifier,
    VersionManagerNotifier,
    NavigationLoader,
  },
  data() {
    return {
      layout: "",
    };
  },
  watch: {
    $route: {
      handler(to) {
        if (to.meta.layout !== undefined) {
          this.layout = to.meta.layout;
        } else {
          this.layout = "AuthenticatedLayout";
        }
      },
    },
  },
  computed: {
    showAppNotifierOnlyOnDesktop(): boolean {
      return !Browser.isMobile();
    },
  },
});
</script>
