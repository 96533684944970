<template>
  <aside class="xl:p-6">
    <div class="flex flex-col flex-grow overflow-y-auto">
      <div
        class="flex flex-col pb-6 mb-6 border-purple-200 border-b-4 border-double"
      >
        <div class="text-lg font-semibold text-gray-900">
          {{ fullName }}
        </div>
        <div class="text-sm text-gray-500">
          {{
            $t("modules.settings.menu.since", {
              signUpDate: signUpDate,
            })
          }}
        </div>
      </div>
      <div class="flex-grow flex flex-col">
        <nav class="flex-1 space-y-4">
          <SettingsMenuItem
            :to="{ name: 'AccountOverview' }"
            route-name="AccountOverview"
            icon="UserSolidIcon"
            :title="$t('modules.settings.menu.account')"
          />
          <SettingsMenuItem
            :to="{ name: 'SubscriptionOverview' }"
            route-name="SubscriptionOverview"
            icon="ArrowUpOutlineIcon"
            :title="$t('modules.settings.menu.subscription')"
            icon-class="fill-white"
            icon-class-selected="fill-white"
          />
          <SettingsMenuItem
            :to="{ name: 'PaymentMethod' }"
            route-name="PaymentMethod"
            icon="CardSolidIcon"
            :title="$t('modules.settings.menu.paymentMethod')"
          />
          <SettingsMenuItem
            :to="{ name: 'BillingOverview' }"
            route-name="BillingOverview"
            icon="MoneySolidIcon"
            :title="$t('modules.settings.menu.billing')"
          />
        </nav>
      </div>
    </div>
  </aside>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import SettingsMenuItem from "@/modules/settings/components/menu/SettingsMenuItem.vue";

import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";
import Datetime from "@/core/shared/helpers/Datetime";

defineOptions({
  name: "SettingsMenu",
});

const fullName = computed((): string => {
  return `${AccountHandler.accountInfo.value?.first_name || ""} ${
    AccountHandler.accountInfo.value?.last_name || ""
  }`;
});

const signUpDate = computed((): string => {
  return Datetime.formatUnixTime(
    AccountHandler.accountInfo.value?.date_updated || 0,
    "short",
  );
});
</script>
