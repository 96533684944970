<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="5" y="6" width="22" height="3" rx="1.5" fill="#111928" />
    <rect x="5" y="14" width="22" height="3" rx="1.5" fill="#111928" />
    <rect x="5" y="22" width="22" height="3" rx="1.5" fill="#111928" />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MenuOutlineIcon",
});
</script>
