<template>
  <footer class="authenticated-footer">
    <div class="authenticated-footer-inner">
      <div class="py-3 md:py-6 flex items-center justify-center">
        <ExternalFooterLink
          href="https://netingest.com/contact"
          target="_blank"
          rel="nofollow"
          class="block md:inline-block link-authenticated-footer"
        >
          {{ $t("core.shared.components.layout.footer.contact") }}
        </ExternalFooterLink>

        <div class="ml-5 text-white">
          © {{ date }} netingest.com {{ version }}
        </div>
      </div>
      <div class="py-3 md:py-6 flex items-center justify-center xl:justify-end">
        <ExternalFooterLink
          :href="'https://netingest.com/privacy'"
          target="_blank"
          class="mr-6 md:py-0.5 link-authenticated-footer"
          rel="nofollow"
        >
          {{ $t("common.privacy") }}
        </ExternalFooterLink>
        <ExternalFooterLink
          :href="'https://netingest.com/terms'"
          target="_blank"
          class="link-authenticated-footer md:py-0.5"
          rel="nofollow"
        >
          {{ $t("common.terms") }}
        </ExternalFooterLink>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import facebook from "@/assets/img/social-media/facebook-gray.svg";
import instagram from "@/assets/img/social-media/instagram-gray.svg";

import ExternalFooterLink from "@/core/shared/components/Link/ExternalFooterLink.vue";

import { ModalHandler } from "@/core/shared/helpers/Modal/ModalHandler";

export default defineComponent({
  name: "AuthenticatedFooter",
  components: {
    ExternalFooterLink,
  },
  data() {
    return {
      date: new Date().getFullYear(),
      version: `v${import.meta.env.PACKAGE_VERSION}`,
      icons: {
        instagram: instagram,
        facebook: facebook,
      },
    };
  },
  methods: {
    onOpenModal() {
      ModalHandler.open("contact-support-modal");
    },
  },
});
</script>

<style lang="scss" scoped>
.authenticated-footer {
  @apply bg-purple-900;

  &-inner {
    @apply px-3 md:px-4 xl:px-6 xl:mx-auto md:flex justify-between;
  }
}

.link-authenticated-footer {
  @apply text-white text-sm font-medium;
}

.text-authenticated-footer {
  @apply text-white text-sm font-normal;
}
</style>
