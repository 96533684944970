import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import RuleService from "@/modules/rule/services/RuleService";
import { BadRequestApiServiceError } from "@/core/shared/services/Error/ApiServiceError";
import ProjectService from "@/modules/project/services/ProjectService";

export default class RuleGuard {
  /**
   * @param  {RouteLocationNormalized} to
   * @param  {RouteLocationNormalized} from
   * @param  {NavigationGuardNext} next
   * @returns void
   */
  static async belongsToUser(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): Promise<void> {
    try {
      to.meta.project = await ProjectService.get(Number(to.params.projectId));
      to.meta.rule = await RuleService.get(
        Number(to.params.ruleId),
        Number(to.params.projectId),
      );
    } catch (error) {
      if (!(error instanceof BadRequestApiServiceError)) {
        throw error;
      }

      return next({
        name: "Dashboard",
      });
    }

    return next();
  }
}
