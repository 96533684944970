import type { AppNotification } from "@/modules/notification/services/NotificationService.types";

export default class NotificationRouterLink {
  static readonly JOB_CONTENT = "job_content";
  static readonly JOB_DELIVERY = "job_delivery";
  static readonly CAMPAIGN_CREATORS = "campaign_creators";
  static readonly WALLET = "wallet";
  static readonly JOB_CHAT = "job_chat";
  static readonly DASHBOARD = "dashboard";
  static readonly CAMPAIGN = "campaign";
  static readonly CERTIFICATION_REJECTED = "certification_rejected";
  static readonly INVOICES_BULK = "invoices_bulk";
  static readonly CREATOR_PAYOUT = "creator_payout";
  static readonly WIRE_TRANSFER_PROCESSED = "wire_transfer_processed";
  static readonly PAYPAL = "paypal";

  static get(notification: AppNotification) {
    if (notification.action_type === NotificationRouterLink.PAYPAL) {
      return {
        name: "WalletWithdrawalMethod",
      };
    }
    if (notification.action_type === NotificationRouterLink.JOB_CONTENT) {
      return {
        name: "JobSummary",
        params: { jobId: notification.action_id },
        hash: "#job-content",
      };
    }
    if (notification.action_type === NotificationRouterLink.JOB_DELIVERY) {
      return {
        name: "JobSummary",
        params: { jobId: notification.action_id },
        hash: "#job-delivery",
      };
    }
    if (notification.action_type === NotificationRouterLink.JOB_CHAT) {
      return {
        name: "JobSummary",
        params: { jobId: notification.action_id },
        hash: "#job-chat",
      };
    }
    if (notification.action_type === NotificationRouterLink.CAMPAIGN_CREATORS) {
      return {
        name: "CampaignSummary",
        params: { campaignId: notification.action_id },
        hash: "#creators",
      };
    }
    if (notification.action_type === NotificationRouterLink.WALLET) {
      return {
        name: "Wallet",
      };
    }
    if (notification.action_type === NotificationRouterLink.DASHBOARD) {
      return {
        name: "Dashboard",
      };
    }
    if (
      notification.action_type === NotificationRouterLink.CERTIFICATION_REJECTED
    ) {
      return notification.action_url;
    }

    if (notification.action_type === NotificationRouterLink.CAMPAIGN) {
      return {
        name: "CampaignSummary",
        params: { campaignId: notification.action_id },
      };
    }
    if (
      notification.action_type === NotificationRouterLink.CERTIFICATION_REJECTED
    ) {
      return notification.action_url;
    }
    if (notification.action_type === NotificationRouterLink.INVOICES_BULK) {
      const link = document.createElement(`a`);
      link.target = "_blank";
      link.href = notification.action_url;
      link.click();
    }
    if (notification.action_type === NotificationRouterLink.CREATOR_PAYOUT) {
      return notification.action_url;
    }
    if (
      notification.action_type ===
      NotificationRouterLink.WIRE_TRANSFER_PROCESSED
    ) {
      return notification.action_url;
    }

    return { name: "" };
  }
}
