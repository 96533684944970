<template>
  <div class="dropdown" data-testid="dropdown">
    <Menu>
      <OnClickOutside @trigger="close">
        <Float
          as="div"
          :placement="placement"
          :offset="10"
          :shift="6"
          :flip="10"
          enter="transition duration-200 ease-out"
          enter-from="scale-95 opacity-0"
          enter-to="scale-100 opacity-100"
          leave="transition duration-150 ease-in"
          leave-from="scale-100 opacity-100"
          leave-to="scale-95 opacity-0"
          tailwindcss-origin-class
          :z-index="zIndex"
          :show="show"
        >
          <MenuButton
            type="button"
            class="dropdown-menu-button group"
            :data-testid="dataTestid"
            @click.stop="toggle"
            @mouseleave="closeOnMouseLeave ? close() : () => null"
          >
            <slot name="button"></slot>
          </MenuButton>
          <MenuItems
            data-testid="dropdown-menu-items"
            class="dropdown-menu"
            :class="width"
            static
            @mouseenter="open"
            @mouseleave="closeOnMouseLeave ? close() : () => null"
          >
            <MenuItem v-for="(item, key) in items" :key="key">
              <router-link
                v-if="item.route"
                :data-testid="item.dataTestid"
                :to="item.route"
                class="text-gray-500 no-underline hover:no-underline text-sm bg-white hover:bg-gray-50 w-full flex items-end px-4 py-2 font-medium"
                :class="item.cssClasses"
              >
                <DisplayIcon
                  v-if="item.icon"
                  :component="item.icon"
                  :class="{ 'h-5 w-5 mr-4': !hasIconClass(item.icon) }"
                />
                {{ item.label }}
                <DisplayIcon
                  v-if="item.suffixIcon"
                  :component="item.suffixIcon"
                  :class="{ 'h-5 w-5 grow': !hasIconClass(item.suffixIcon) }"
                />
              </router-link>
              <button
                v-else
                type="button"
                @click.stop="callFunction(item)"
                :data-testid="item.dataTestid"
                class="text-gray-500 no-underline hover:no-underline text-sm bg-white hover:bg-gray-50 w-full flex items-end px-4 py-2 font-medium"
                :class="item.cssClasses"
              >
                <DisplayIcon
                  v-if="item.icon"
                  :component="item.icon"
                  :class="{ 'h-5 w-5 mr-4': !hasIconClass(item.icon) }"
                />
                {{ item.label }}
                <DisplayIcon
                  v-if="item.suffixIcon"
                  :component="item.suffixIcon"
                  :class="{ 'h-5 w-5 grow': !hasIconClass(item.suffixIcon) }"
                />
              </button>
            </MenuItem>
          </MenuItems>
        </Float>
      </OnClickOutside>
    </Menu>
  </div>
</template>

<script lang="ts">
/**
 * Dropdown component
 * @version 1.3.1 - 2023-01-16
 */
import { defineComponent } from "vue";
import type { DisplayIconProp } from "@/core/shared/components/Dropdown/DisplayIcon.vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { Float } from "@headlessui-float/vue";
import { OnClickOutside } from "@vueuse/components";
import type { PropType } from "vue";

import DisplayIcon from "@/core/shared/components/Dropdown/DisplayIcon.vue";

import type { DropdownItem } from "@/core/shared/components/Dropdown/Dropdown.types";
import type { PlacementsOptions } from "@/core/shared/types/Placements";

export default defineComponent({
  name: "Dropdown",
  inheritAttrs: false,
  components: {
    DisplayIcon,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    Float,
    OnClickOutside,
  },
  props: {
    placement: {
      type: String as PropType<PlacementsOptions>,
      default: "bottom-end",
    },
    items: {
      type: Array as PropType<DropdownItem[]>,
      required: true,
    },
    width: {
      type: String,
      default: "w-52",
    },
    dataTestid: {
      type: String,
      default: "dropdown-button",
    },
    delay: {
      type: Number,
      default: 100,
    },
    closeOnMouseLeave: {
      type: Boolean,
      default: false,
    },
    zIndex: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      show: false,
      openTimer: 0,
      closeTimer: 0,
    };
  },
  methods: {
    toggle(): void {
      this.show = !this.show;
    },
    open(): void {
      if (this.closeTimer) {
        clearTimeout(this.closeTimer);
      }
      this.openTimer = window.setTimeout(() => {
        this.show = true;
      }, this.delay);
    },
    close(): void {
      if (this.openTimer) {
        clearTimeout(this.openTimer);
      }

      this.closeTimer = window.setTimeout(
        () => {
          this.show = false;
        },
        this.closeOnMouseLeave ? this.delay : 0,
      );
    },
    callFunction(item: DropdownItem): void {
      this.close();
      if (item.action && typeof item.action === "function") {
        item.action();
      }
    },
    hasIconClass(iconComponent: DisplayIconProp): boolean {
      return (
        !!iconComponent.attributes &&
        Reflect.has(iconComponent.attributes, "class")
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.dropdown {
  &-menu-button {
    @apply flex justify-center items-center;
  }

  &-menu {
    @apply rounded-lg shadow-lg focus:outline-0 ring-1 ring-black bg-white ring-opacity-5 overflow-hidden -mt-2 py-2;
  }
}
</style>
