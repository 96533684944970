import { DsNotifier } from "@devsalsa/vue-core";
import i18n from "@/i18n";
import { useDsToastNotifierStore } from "@devsalsa/vue-core/dist/components/toast/DsToastNotifierStore";
import { useApiStore } from "@/store/ApiStore";

export class ConnectivityHandler {
  static setOffline(): void {
    useApiStore().setOffline();
    if (useDsToastNotifierStore().connectivityNotificationId === 0) {
      //Send notification
      const id = DsNotifier.sendWarning({
        message: i18n.global.t("common.error.global.connectivityError"),
        duration: 0,
        enableClose: false,
        position: "top-0 right-0",
        overlay: true,
      });
      useDsToastNotifierStore().setConnectivityId(id);
    }
  }
  static setOnline(): void {
    useApiStore().setOnline();
    DsNotifier.delete(useDsToastNotifierStore().connectivityNotificationId);
    useDsToastNotifierStore().setConnectivityId(0);
  }
  static isOnline(): boolean {
    return useApiStore().connection.online;
  }
}
