import { ApiService } from "@/core/shared/services/ApiService";
import type {
  Project,
  ProjectCreated,
  ProjectsResponse,
  ProjectUpdated,
} from "@/modules/project/services/ProjectService.types";
import type { RuleDimension } from "@/shared/services/AssetService.types";
import type { ProjectGranularityStats } from "@/modules/project/services/ProjectService.types";

/**
 * @class ProjectService
 */
export default class ProjectService {
  /**
   * @param name
   * @returns
   */
  static create(name: string): Promise<ProjectCreated> {
    return ApiService.post("/project", {
      name,
    });
  }

  /**
   * @param id
   * @param name
   * @returns
   */
  static updateName(id: number, name: string): Promise<ProjectUpdated> {
    return ApiService.put(`/project/${id}`, {
      name,
    });
  }

  /**
   * @param id
   * @param data
   * @returns
   */
  static updateCaptcha(id: number, data: FormData): Promise<ProjectUpdated> {
    return ApiService.put(`/project/${id}/captcha`, data);
  }

  /**
   * @param id
   * @returns
   */
  static get(id: number): Promise<Project> {
    return ApiService.get(`/project/${id}`);
  }

  /**
   * @param id
   * @param data
   * @returns
   */
  static getStats(
    id: number,
    data: Record<string, unknown>,
  ): Promise<ProjectGranularityStats[]> {
    return ApiService.post(`/project/${id}/stats`, data);
  }

  /**
   * @returns
   */
  static getDimensions(id: number): Promise<RuleDimension[]> {
    return ApiService.get(`/project/${id}/access_request_fields`);
  }

  /**
   * @returns
   * @param projectId
   */
  static delete(projectId: number): Promise<ProjectUpdated> {
    return ApiService.delete(`/project/${projectId}`);
  }

  /**
   * @returns
   * @param page
   * @param rows
   */
  static list(page = 0, rows = 10): Promise<ProjectsResponse> {
    return ApiService.get(`/projects/${page}/${rows}`);
  }
}
