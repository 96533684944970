import Notifier from "@/core/shared/helpers/Notifier/Notifier";
import { useApiStore } from "@/store/ApiStore";
import { useNotifierStore } from "@/store/NotifierStore";
import { ApiErrorTranslator } from "@/core/shared/helpers/Error/ApiErrorTranslator";
import type { ApiServiceError } from "@/core/shared/services/Error/ApiServiceError";

export class MaintenanceHandler {
  static setOn(error: ApiServiceError) {
    useApiStore().setMaintenanceOn();
    if (useNotifierStore().maintenanceNotificationId === 0) {
      const id = Notifier.sendInfo({
        message: ApiErrorTranslator.translate(error),
        duration: 0,
        enableClose: false,
        position: "top-0 right-0",
        overlay: true,
      });
      useNotifierStore().setMaintenanceId(id);
    }
  }
  static setOff() {
    useApiStore().setMaintenanceOff();
    Notifier.deleteNotification(useNotifierStore().maintenanceNotificationId);
    useNotifierStore().setMaintenanceId(0);
  }
}
