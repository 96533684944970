<template>
  <div class="account-layout">
    <UnauthenticatedNavbar />
    <div class="account-layout-content">
      <div class="account-layout-wrapper">
        <div class="account-layout-wrapper-card">
          <div class="account-layout-wrapper-card-content">
            <RouterView v-slot="{ Component, route }">
              <transition name="fade" mode="out-in">
                <component :is="Component" :key="route.name" />
              </transition>
            </RouterView>
          </div>
        </div>
      </div>
    </div>
    <UnauthenticatedFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UnauthenticatedFooter from "@/core/shared/components/Layout/UnauthenticatedFooter.vue";
import UnauthenticatedNavbar from "@/core/shared/components/Navigation/UnauthenticatedNavbar.vue";

export default defineComponent({
  name: "AccountLayout",
  components: {
    UnauthenticatedFooter,
    UnauthenticatedNavbar,
  },
});
</script>

<style lang="scss" scoped>
.account-layout {
  &-content {
    @apply flex justify-center items-center
    mt-16
    min-h-[calc(100vh_-_192px)] sm:min-h-[calc(100vh_-_128px)];
  }

  &-wrapper {
    @apply w-full sm:w-4/5 lg:w-2/5 2xl:w-2/5 max-w-[28rem];

    &-card {
      @apply flex flex-col sm:rounded-2xl;

      &-content {
        @apply xs:px-6 px-4 sm:px-0 w-full;
      }
    }
  }
}
</style>
