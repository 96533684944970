<template>
  <a
    :href="href"
    :target="target"
    :class="[
      getThemeDisplay,
      `${getThemeDisplay}-${themeColor}`,
      getButtonSize,
      getButtonStyle,
      getButtonOpacity,
      {
        'btn-icon': icon,
        'btn-outline': outline,
      },
    ]"
  >
    <slot></slot>
  </a>
</template>

<script lang="ts">
/**
 * ExternalLink component
 * @version 1.0.1 - 2023-01-06
 */
import { defineComponent } from "vue";
import type { ButtonOpacity } from "@/core/shared/components/Button/Button.vue";
import type { PropType } from "vue";

import type {
  ButtonSize,
  DisplayType,
  ThemeColor,
} from "@/core/shared/types/DisplayOptions";

export default defineComponent({
  name: "ExternalLink",
  props: {
    href: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      required: false,
    },
    themeDisplay: {
      type: String as PropType<DisplayType>,
      required: false,
      default: "link",
    },
    themeColor: {
      type: String as PropType<ThemeColor>,
      required: false,
      default: "black",
    },
    outline: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<ButtonSize>,
      required: false,
      default: "md",
    },
    icon: {
      type: Boolean,
      required: false,
      default: false,
    },
    pill: {
      type: Boolean,
      default: false,
    },
    opacity: {
      type: String as PropType<ButtonOpacity>,
      default: "100",
    },
  },
  computed: {
    getThemeDisplay(): string {
      return this.themeDisplay === "button" ? "btn" : "link";
    },
    getButtonSize(): string {
      return this.themeDisplay === "button" ? `btn-${this.size}` : "";
    },
    getButtonStyle(): string {
      return this.themeDisplay === "button" && this.pill ? "btn-pill" : "";
    },
    getButtonOpacity(): string {
      return Number(this.opacity) < 100 ? `btn-opacity-${this.opacity}` : "";
    },
  },
});
</script>
