<template>
  <div class="authenticated-layout" id="authenticated-layout">
    <AuthenticatedLayoutHeader />
    <div class="authenticated-layout-content" id="layout-content">
      <main class="authenticated-layout-content-main">
        <RouterView v-slot="{ Component, route }">
          <transition name="fade" mode="out-in">
            <component :is="Component" :key="route.name" />
          </transition>
        </RouterView>
      </main>
      <AuthenticatedFooter />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import AuthenticatedFooter from "@/core/shared/components/Layout/AuthenticatedFooter.vue";
import AuthenticatedLayoutHeader from "@/core/shared/components/Layout/AuthenticatedLayoutHeader.vue";

export default defineComponent({
  name: "AuthenticatedLayout",
  components: {
    AuthenticatedLayoutHeader,
    AuthenticatedFooter,
  },
});
</script>

<style lang="scss" scoped>
.authenticated-layout {
  @apply flex lg:h-screen flex-col;

  &-content {
    @apply w-full h-full bg-gray-50 flex-1 lg:overflow-y-auto;

    &-main {
      min-height: calc(100vh - 64px);
    }
  }
}
</style>
