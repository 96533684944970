import DashboardGuard from "@/modules/dashboard/helpers/DashboardGuard";

export default [
  {
    path: "",
    beforeEnter: DashboardGuard.withCompleteOnboarding,
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () =>
          import("@/modules/dashboard/views/OverviewDashboard.vue"),
        meta: {
          title: "modules.dashboard.views.overviewDashboard.meta.title",
        },
      },
    ],
  },
];
