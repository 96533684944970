<template>
  <div class="project-layout">
    <AuthenticatedLayoutHeader />
    <div class="project-layout-content">
      <div class="project-layout-content-inner">
        <div
          v-if="$grid('hidden xl:block')"
          class="xl:w-[224px] bg-white border-r border-gray-200"
        >
          <ProjectMenu class="top-0 xl:sticky" />
        </div>
        <main
          class="grow xl:max-w-[calc(100%_-_224px)] sm:px-3 md:px-4 xl:px-6 py-6 space-y-6"
        >
          <RouterView v-slot="{ Component, route }">
            <transition name="fade" mode="out-in">
              <component :is="Component" :key="route.name" />
            </transition>
          </RouterView>
        </main>
      </div>
      <AuthenticatedFooter />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import AuthenticatedFooter from "@/core/shared/components/Layout/AuthenticatedFooter.vue";
import AuthenticatedLayoutHeader from "@/core/shared/components/Layout/AuthenticatedLayoutHeader.vue";

import ProjectMenu from "@/modules/project/components/menu/ProjectMenu.vue";

export default defineComponent({
  name: "ProjectLayout",
  components: { ProjectMenu, AuthenticatedFooter, AuthenticatedLayoutHeader },
});
</script>

<style lang="scss" scoped>
.project-layout {
  &-content {
    @apply bg-gray-50;

    &-inner {
      @apply flex flex-row min-h-[calc(100vh_-_64px)];
    }
  }
}
</style>
