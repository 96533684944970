import DashboardGuard from "@/modules/dashboard/helpers/DashboardGuard";

export default [
  {
    path: "/subscription",
    beforeEnter: DashboardGuard.withCompleteOnboarding,
    children: [
      {
        path: "",
        name: "SubscriptionOverview",
        component: () =>
          import("@/modules/subscription/views/SubscriptionOverview.vue"),
        meta: {
          title: "modules.subscription.views.subscriptionOverview.meta.title",
          layout: "SubscriptionLayout",
        },
      },
      {
        path: "setup/cancel",
        name: "SubscriptionSetupCancellation",
        component: () =>
          import(
            "@/modules/subscription/views/SubscriptionSetupCancellation.vue"
          ),
        meta: {
          title:
            "modules.subscription.views.subscriptionSetupCancellation.meta.title",
        },
      },
      {
        path: "setup/success/:session",
        name: "SubscriptionSetupSuccess",
        component: () =>
          import("@/modules/subscription/views/SubscriptionSetupSuccess.vue"),
        meta: {
          title:
            "modules.subscription.views.subscriptionSetupSuccess.meta.title",
        },
      },
    ],
  },
];
