<template>
  <router-link
    :to="to"
    class="settings-menu-item group"
    :class="[isSelected ? customClassSelected : customClass]"
  >
    <component
      :is="icon"
      class="settings-icon"
      :class="[isSelected ? iconClassSelected : iconClass]"
    />
    <span v-if="!hasSlotTitle" class="settings-label">
      {{ title }}
    </span>
    <slot v-else :name="slotName" />
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ArrowUpOutlineIcon from "@/shared/components/icon/ArrowUpOutlineIcon.vue";
import CardSolidIcon from "@/shared/components/icon/CardSolidIcon.vue";
import MoneySolidIcon from "@/shared/components/icon/MoneySolidIcon.vue";
import UserSolidIcon from "@/shared/components/icon/UserSolidIcon.vue";

export default defineComponent({
  name: "SettingsMenuItem",
  components: {
    UserSolidIcon,
    CardSolidIcon,
    MoneySolidIcon,
    ArrowUpOutlineIcon,
  },
  props: {
    to: {
      type: Object,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
      default: "fill-gray-400",
    },
    iconClassSelected: {
      type: String,
      default: "fill-gray-900",
    },
    customClass: {
      type: String,
      default: "text-gray-500",
    },
    customClassSelected: {
      type: String,
      default: "text-gray-900",
    },
  },
  data() {
    return {
      slotName: "title",
    };
  },
  computed: {
    isSelected(): boolean {
      const isRouteChild =
        !!this.$route.meta.breadcrumb &&
        this.$route.meta.breadcrumb === this.routeName;

      return this.$route.name === this.routeName || isRouteChild;
    },
    hasSlotTitle(): boolean {
      return Reflect.has(this.$slots, this.slotName);
    },
  },
});
</script>

<style lang="scss" scoped>
.settings-menu-item {
  @apply flex items-center p-1 text-sm font-medium rounded-md transition-colors duration-100;

  .settings-icon {
    @apply h-5 w-5 block mr-4 shrink-0 group-hover:fill-gray-900;
  }

  .settings-label {
    @apply flex-1 group-hover:text-gray-900;
  }
}
</style>
