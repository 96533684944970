<template>
  <TransitionRoot as="template" :show="isShowing">
    <Dialog as="div" class="relative z-40" @close="closeSidebar">
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-900 bg-opacity-30 transition-opacity"
        />
      </TransitionChild>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel
                class="pointer-events-auto w-screen w-[312px] sm:w-[400px]"
              >
                <div class="flex h-full flex-col bg-white shadow-xl">
                  <div class="flex min-h-0 flex-1 flex-col">
                    <div
                      class="flex h-16 items-start items-center justify-between border-b border-gray-200 px-5"
                    >
                      <DialogTitle class="text-xl font-semibold text-gray-900">
                        {{ $t("modules.notification.h1") }}
                      </DialogTitle>
                      <button
                        class="h-5 w-5 cursor-pointer text-gray-900"
                        @click="closeSidebar"
                      >
                        <CloseOutlineIcon />
                      </button>
                    </div>
                    <div
                      class="relative flex-1 overflow-y-auto border-b border-gray-200"
                    >
                      <NotificationList
                        v-if="!populating"
                        :notifications="notifications"
                        :totalRecords="totalRecords"
                        is-sidebar
                        :disabled-navSidebar="disabledNavSidebar"
                        @notification-read="onNotificationRead"
                        @close-Sidebar="closeSidebar"
                      />
                      <DsSkeleton
                        v-else
                        v-for="index in 10"
                        :key="index"
                        height="110px"
                        width="100%"
                        class="mb-[1px] block h-[110px] !rounded-none"
                      />
                      <!-- /End replace -->
                    </div>
                  </div>
                  <div
                    v-if="totalRecords > 0"
                    class="grid grid-cols-1 gap-x-5 p-5 space-y-4 sm:space-y-0 sm:grid-cols-2"
                  >
                    <DsButton outline size="lg" @click="readAllNotifications">
                      {{ $t("common.button.markAllRead") }}
                    </DsButton>
                    <DsLink
                      @click.stop="closeSidebar"
                      outline
                      size="lg"
                      theme-display="button"
                      :to="{ name: 'Notifications' }"
                    >
                      {{ $t("common.button.showAll") }}
                    </DsLink>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useAppNotifierStore } from "@/store/AppNotifierStore";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import {
  DsButton,
  DsFlashNotifier,
  DsLink,
  DsSkeleton,
} from "@devsalsa/vue-core";

import NotificationList from "@/modules/notification/components/NotificationList.vue";

import CloseOutlineIcon from "@/shared/components/icon/CloseOutlineIcon.vue";

import NotificationService from "@/modules/notification/services/NotificationService";

import type { AppNotification } from "@/modules/notification/services/NotificationService.types";

import { ErrorTranslator } from "@/core/shared/helpers/Error/ErrorTranslator";
import AppNotifier from "@/core/shared/helpers/Notifier/AppNotifier";
import { BadRequestApiServiceError } from "@/core/shared/services/Error/ApiServiceError";

export default defineComponent({
  name: "NotificationsSidebar",
  components: {
    CloseOutlineIcon,
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    DsButton,
    NotificationList,
    DsLink,
    DsSkeleton,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      populating: false,
      totalRecords: 0,
      notifications: [] as AppNotification[],
      currentPage: 1,
      recordsPerPage: 50,
      flagOnlyOnce: true,
      unsubscribeAppNotifier: () => {
        return;
      },
    };
  },
  async created() {
    this.unsubscribeAppNotifier = useAppNotifierStore().$onAction(
      ({ name, after }) => {
        after(() => {
          if (name === "setUnread") {
            this.setFlagOnlyOnce(true);
          }
        });
      },
    );
  },
  unmounted() {
    this.unsubscribeAppNotifier();
  },
  computed: {
    isShowing: {
      get() {
        if (this.show && this.flagOnlyOnce) {
          this.setFlagOnlyOnce(false);
          this.populateData(this.currentPage);
        }
        return this.show;
      },
      set(val: boolean) {
        this.$emit("update:show", val);
      },
    },
    disabledNav(): boolean {
      return this.totalRecords <= 0;
    },
    disabledNavSidebar(): boolean {
      return this.disabledNav;
    },
  },
  methods: {
    closeSidebar() {
      this.isShowing = false;
    },
    async populateData(page: number) {
      this.populating = true;
      try {
        this.currentPage = page;
        const response = await NotificationService.search(
          page - 1,
          this.recordsPerPage,
        );
        this.totalRecords = response.total_rows;
        this.notifications = response.rows;
        this.populating = false;
      } catch (error) {
        if (!(error instanceof BadRequestApiServiceError)) {
          throw error;
        }
        DsFlashNotifier.error(ErrorTranslator.translate(error));
      }
    },
    async readAllNotifications() {
      this.populating = true;
      try {
        await NotificationService.markReadAll();
      } catch (error) {
        if (!(error instanceof BadRequestApiServiceError)) {
          throw error;
        }
        DsFlashNotifier.error(ErrorTranslator.translate(error));
      } finally {
        await this.populateData(1);
        this.populating = false;
      }
    },
    async onNotificationRead(notificationId: number) {
      this.populating = true;
      try {
        await AppNotifier.markRead(notificationId);
      } catch (error) {
        if (!(error instanceof BadRequestApiServiceError)) {
          throw error;
        }
        DsFlashNotifier.error(ErrorTranslator.translate(error));
      } finally {
        await this.populateData(this.currentPage);
        this.populating = false;
      }
    },
    setFlagOnlyOnce(value: boolean) {
      this.flagOnlyOnce = value;
    },
  },
});
</script>
