import AuthGuard from "@/core/shared/helpers/Auth/AuthGuard";

export default [
  {
    path: "/settings",
    beforeEnter: AuthGuard.withVerifiedEmail,
    children: [
      {
        path: "",
        name: "Settings",
        redirect: { name: "AccountOverview" },
      },
      {
        path: "account",
        name: "AccountOverview",
        component: () => import("@/modules/settings/views/AccountOverview.vue"),
        meta: {
          title: "modules.settings.views.accountOverview.meta.title",
          layout: "SettingsLayout",
        },
      },
      {
        path: "billing",
        name: "BillingOverview",
        component: () => import("@/modules/settings/views/BillingOverview.vue"),
        meta: {
          title: "modules.settings.views.billingOverview.meta.title",
          layout: "SettingsLayout",
        },
      },
      {
        path: "payment-method",
        name: "PaymentMethod",
        component: () => import("@/modules/settings/views/PaymentMethod.vue"),
        meta: {
          title: "modules.settings.views.paymentMethod.meta.title",
          layout: "SettingsLayout",
        },
      },
      {
        path: "payment-method/setup/cancel",
        name: "PaymentMethodSetupCancellation",
        component: () =>
          import("@/modules/settings/views/PaymentMethodSetupCancellation.vue"),
        meta: {
          title:
            "modules.settings.views.paymentMethodSetupCancellation.meta.title",
          layout: "SettingsLayout",
        },
      },
      {
        path: "payment-method/setup/success/:session",
        name: "PaymentMethodSetupSuccess",
        component: () =>
          import("@/modules/settings/views/PaymentMethodSetupSuccess.vue"),
        meta: {
          title: "modules.settings.views.paymentMethodSetupSuccess.meta.title",
          layout: "SettingsLayout",
        },
      },
    ],
  },
];
