import { ref } from "vue";
import * as Sentry from "@sentry/vue";
import AccountService from "@/core/modules/account/services/AccountService";
import type { AccountInfo } from "@/core/modules/account/services/AccountService.types";
import AuthService from "@/core/shared/services/Auth/AuthService";
import { WebSocketService } from "@/core/shared/services/WebSocket/WebSocketService";
import type { PromisifyFn } from "@vueuse/core";
import { useDebounceFn } from "@vueuse/core";

export default class AccountHandler {
  static accountInfo: { value: null | AccountInfo } = ref(null);

  /**
   *
   * @param update
   * @returns Object
   */
  static async getInfo(update = false) {
    if (!this.accountInfo.value || update) {
      this.accountInfo.value = await AccountService.getInfo();
      if (import.meta.env.VITE_APP_SENTRY == "TRUE") {
        Sentry.setUser({ email: this.accountInfo.value.email });
      }
    }
    return this.accountInfo.value;
  }

  /**
   * @returns PromisifyFn<() => Promise<void>>
   */
  static getDebounceInfo(): PromisifyFn<() => Promise<void>> {
    return useDebounceFn(async () => {
      await AccountHandler.getInfo(true);
    }, 3000);
  }

  /**
   * @param token
   * @returns Object
   */
  static async authenticate(token: string) {
    AuthService.setToken(token);
    if (WebSocketService.isConnected()) {
      WebSocketService.authenticate();
    }
    return await AccountHandler.getInfo(true);
  }
}
