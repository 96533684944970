import ImageKit from "imagekit-javascript";

export default class ImageKitUrl {
  static get(src: string, namedTransformation: string) {
    const config = {
      publicKey: import.meta.env.VITE_APP_IMAGEKIT_PUBLIC_KEY,
      urlEndpoint: import.meta.env.VITE_APP_IMAGEKIT_URL_ENDPOINT,
      authenticationEndpoint: import.meta.env.VITE_APP_HOST,
    };

    const imagekit = new ImageKit(config);
    if (!imagekit) {
      //If we have not been able to obtain an instance from the ImageKit SDK, use the original URL.
      return src;
    }
    // Destructure path in sections
    const { pathname } = new URL(src);
    const imgName = pathname.split("/").slice(-1).toString();
    const directory = pathname.replace(imgName, "").substring(1);

    //Get Url from imageKit resize
    return imagekit.url({
      path: imgName,
      urlEndpoint: `${
        import.meta.env.VITE_APP_IMAGEKIT_URL_ENDPOINT
      }/${directory}`,
      transformation: [
        {
          named: namedTransformation,
        },
      ],
    });
  }

  static getTransformationFromNumber(size: number) {
    if (size === 1080) {
      return "xl";
    }
    if (size === 800) {
      return "lg";
    }
    if (size === 500) {
      return "md";
    }
    if (size === 300) {
      return "sm";
    }
    return "xs";
  }
}
