import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import AuthService from "@/core/shared/services/Auth/AuthService";
import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";
import ProjectService from "@/modules/project/services/ProjectService";

export default class OnboardingGuard {
  static async withoutProject(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) {
    if (AuthService.isAuthenticated()) {
      const accountInfo = await AccountHandler.getInfo();
      if (
        accountInfo &&
        accountInfo.email != null &&
        accountInfo.email_verified
      ) {
        const projects = await ProjectService.list();
        if (projects.total_rows > 0) {
          return next({
            name: "Dashboard",
          });
        }

        return next();
      }

      return next({
        name: "ConfirmEmail",
      });
    }

    return next({
      name: "Login",
    });
  }
}
