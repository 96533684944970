<template>
  <div class="subscription-setup-layout">
    <UnauthenticatedNavbar />
    <div class="subscription-setup-layout-content">
      <RouterView v-slot="{ Component, route }">
        <transition name="fade" mode="out-in">
          <component :is="Component" :key="route.name" />
        </transition>
      </RouterView>
    </div>
    <UnauthenticatedFooter />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import UnauthenticatedFooter from "@/core/shared/components/Layout/UnauthenticatedFooter.vue";
import UnauthenticatedNavbar from "@/core/shared/components/Navigation/UnauthenticatedNavbar.vue";

export default defineComponent({
  name: "SubscriptionSetupLayout",
  components: {
    UnauthenticatedNavbar,
    UnauthenticatedFooter,
  },
});
</script>

<style lang="scss" scoped>
.subscription-setup-layout {
  &-content {
    @apply flex justify-center items-center
    bg-gray-50 lg:bg-white
    mt-16
    min-h-[calc(100vh_-_192px)] sm:min-h-[calc(100vh_-_128px)]
    w-full h-full py-8 sm:px-6;
  }
}
</style>
