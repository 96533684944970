import "@/assets/scss/index.scss";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import i18n from "@/i18n";
import gtm from "@/gtm";
import { ErrorHandler } from "@/core/shared/helpers/Error/ErrorHandler";
import Currency from "@/core/shared/plugins/Currency";
import Network from "@/core/shared/plugins/Network";
import Grid from "@/core/shared/plugins/Grid";
import Datetime from "@/core/shared/plugins/Datetime";

const pinia = createPinia();
const app = createApp(App)
  .use(pinia)
  .use(i18n)
  .use(Currency)
  .use(Network)
  .use(Grid)
  .use(Datetime);

app.config.performance = true;
app.config.errorHandler = (error: unknown, instance, info) => {
  console.log("VueJS error handler", error, instance, info);
  ErrorHandler.handle(error);
};
if (import.meta.env.VITE_APP_SENTRY == "TRUE") {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    environment: import.meta.env.VITE_APP_ENVIRONMENT,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.5,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [
          "localhost",
          import.meta.env.VITE_APP_SENTRY_ORIGIN,
          /^\//,
        ],
      }),
      new Sentry.Replay(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
  });
}
if (import.meta.env.VITE_APP_GTM == "TRUE") {
  app.use(gtm);
}

app.use(router).mount("#app");

export default app;
