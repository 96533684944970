<script lang="ts">
import { defineComponent, h } from "vue";
import type { DefineComponent, PropType, VNodeProps } from "vue";

export interface DisplayIconProp {
  name: unknown;
  attributes?: unknown;
}

/**
 * DisplayIcon component
 */
export default defineComponent({
  name: "DisplayIcon",
  props: {
    component: {
      type: Object as PropType<DisplayIconProp>,
      required: true,
    },
  },
  setup(props) {
    return () =>
      h(
        props.component.name as DefineComponent,
        props.component.attributes as VNodeProps,
      );
  },
});
</script>
