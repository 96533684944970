import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import AuthService from "@/core/shared/services/Auth/AuthService";
import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";
import { ClearLocalData } from "@/core/shared/helpers/LocalData";

export default class AuthGuard {
  /**
   * @param  {RouteLocationNormalized} to
   * @param  {RouteLocationNormalized} from
   * @param  {NavigationGuardNext} next
   * @returns void
   */
  static withoutToken(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): void {
    if (AuthService.isAuthenticated()) {
      return next({
        name: "Dashboard",
      });
    }

    return next();
  }

  /**
   * @param  {RouteLocationNormalized} to
   * @param  {RouteLocationNormalized} from
   * @param  {NavigationGuardNext} next
   * @returns void
   */
  static withToken(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ): void {
    if (AuthService.isAuthenticated()) {
      return next();
    }

    return next({
      name: "Login",
    });
  }

  /**
   * @param  {RouteLocationNormalized} to
   * @param  {RouteLocationNormalized} from
   * @param  {NavigationGuardNext} next
   * @returns void
   */
  static async withoutEmail(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) {
    //The user is not authenticated, send him to the login page.
    if (AuthService.isAuthenticated()) {
      const accountInfo = await AccountHandler.getInfo();
      const condition = accountInfo && accountInfo.email == null;
      if (condition) {
        return next();
      }

      return next({
        name: "VerifyEmail",
      });
    }

    return next({
      name: "Login",
    });
  }

  /**
   * @param  {RouteLocationNormalized} to
   * @param  {RouteLocationNormalized} from
   * @param  {NavigationGuardNext} next
   * @returns void
   */
  static async withUnverifiedEmail(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) {
    //The user is not authenticated, send him to the login page.
    if (AuthService.isAuthenticated()) {
      const accountInfo = await AccountHandler.getInfo();
      const condition =
        accountInfo &&
        accountInfo.email != null &&
        accountInfo.email_verified == 0;
      if (condition) {
        return next();
      }

      return next({
        name: "Dashboard",
      });
    }

    return next({
      name: "Login",
    });
  }

  /**
   * @param  {RouteLocationNormalized} to
   * @param  {RouteLocationNormalized} from
   * @param  {NavigationGuardNext} next
   * @returns void
   */
  static async withoutVerifiedEmail(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) {
    if (AuthService.isAuthenticated()) {
      const accountInfo = await AccountHandler.getInfo();
      const condition =
        accountInfo && accountInfo.email != null && accountInfo.email_verified;
      if (!condition) {
        to.meta.accountInfo = accountInfo;
        return next();
      }

      return next({
        name: "Dashboard",
      });
    }

    return next({
      name: "Login",
    });
  }

  /**
   * @param  {RouteLocationNormalized} to
   * @param  {RouteLocationNormalized} from
   * @param  {NavigationGuardNext} next
   * @returns void
   */
  static async withVerifiedEmail(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) {
    if (AuthService.isAuthenticated()) {
      const accountInfo = await AccountHandler.getInfo();
      const condition =
        accountInfo && accountInfo.email != null && accountInfo.email_verified;
      if (condition) {
        return next();
      }

      return next({
        name: "ConfirmEmail",
      });
    }

    return next({
      name: "Login",
    });
  }

  static async impersonate(to: RouteLocationNormalized) {
    //Clear all keys from local storage
    ClearLocalData();
    await AccountHandler.authenticate(to.params.token.toString());
  }
}
