export default [
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("@/modules/page-not-found/views/PageNotFound.vue"),
    meta: {
      title: "common.route.pageNotFound.meta",
      layout: "PageNotFoundLayout",
    },
  },
];
