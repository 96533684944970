import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import AuthService from "@/core/shared/services/Auth/AuthService";
import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";
import ProjectService from "@/modules/project/services/ProjectService";

export default class DashboardGuard {
  static async withCompleteOnboarding(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) {
    if (!AuthService.isAuthenticated()) {
      return next({
        name: "Login",
      });
    }

    const accountInfo = await AccountHandler.getInfo();
    if (!accountInfo) {
      return next({
        name: "Login",
      });
    }

    if (accountInfo?.email != null && !accountInfo?.email_verified) {
      return next({
        name: "ConfirmEmail",
      });
    }

    const projects = await ProjectService.list();
    if (projects.total_rows === 0) {
      return next({
        name: "OnboardingProject",
      });
    }

    next();
  }
}
