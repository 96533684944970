<template>
  <footer class="unauthenticated-footer">
    <div class="unauthenticated-footer-copyright">
      © {{ date }} netingest.com
      {{ $t("core.shared.components.layout.footer.rights") }}
      <span class="unauthenticated-footer-copyright-version">{{
        version
      }}</span>
    </div>
    <div class="unauthenticated-footer-links">
      <ExternalFooterLink
        :href="'https://netingest.com/privacy'"
        target="_blank"
        rel="nofollow"
      >
        {{ $t("common.privacy") }}
      </ExternalFooterLink>
      <ExternalFooterLink
        :href="'https://netingest.com/terms'"
        target="_blank"
        rel="nofollow"
      >
        {{ $t("common.terms") }}
      </ExternalFooterLink>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { ref } from "vue";

import ExternalFooterLink from "@/core/shared/components/Link/ExternalFooterLink.vue";

defineOptions({
  name: "UnauthenticatedFooter",
});

const date = ref(new Date().getFullYear());
const version = `v${import.meta.env.PACKAGE_VERSION}`;
</script>

<style lang="scss" scoped>
.unauthenticated-footer {
  @apply w-full bg-gray-50 lg:bg-transparent px-4 py-2 lg:px-8 h-32 sm:h-16
  flex flex-col md:flex-row md:items-center justify-between relative;

  &-copyright {
    @apply text-gray-500 text-sm lg:text-base;

    &-version {
      @apply absolute sm:static right-2 bottom-2 text-gray-500;
    }
  }

  &-links {
    @apply flex flex-col sm:items-center sm:flex-row md:justify-end gap-1 sm:gap-5 sm:grow;
  }
}
</style>
