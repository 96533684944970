<template>
  <DsLazyImage
    :src="cloudSrc"
    :alt="alt"
    :populating="populating"
    :populatingClass="populatingClass"
    ref="imgResize"
    @loaded="$emit('loaded')"
    @error="$emit('error')"
    :cover="cover"
    :mask="mask"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";

import { DsLazyImage } from "@devsalsa/vue-core";

import type { MaskType } from "@/core/shared/types/DisplayOptions";

import ImageKitUrl from "@/core/shared/helpers/ImageKitUrl";

const sizes = import.meta.env.VITE_APP_IMAGEKIT_IMAGE_SIZES.split(",").map(
  (size: string) => parseInt(size),
);

export default defineComponent({
  components: { DsLazyImage },
  emits: ["loaded", "error"],
  props: {
    alt: String,
    src: {
      type: String,
      required: true,
      default: "",
    },
    populating: {
      type: Boolean,
      default: false,
    },
    populatingClass: {
      type: String,
      default: "",
    },
    cover: {
      type: Boolean,
      default: true,
    },
    mask: {
      type: String as PropType<MaskType>,
      default: "square",
    },
  },
  data() {
    return {
      size: sizes.values().next().value,
      cloudSrc: undefined as undefined | string,
    };
  },
  mounted() {
    const ro = new ResizeObserver(() => {
      this.newSrc();
    });

    // Only observe the box
    const ref = this.$refs.imgResize as InstanceType<typeof DsLazyImage>;
    ro.observe(ref.$el);

    //Load the original size required for the image.
    this.newSrc();
  },
  watch: {
    src(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.newSrc();
      }
    },
  },
  methods: {
    newSrc() {
      //Determine the image size according to its container.
      this.applySize();
      //Validate is URL and from S3 Bucket
      let validationUrl = false;
      import.meta.env.VITE_APP_S3_BUCKET_PUBLIC.split(",").forEach(
        (bucket: string) => {
          if (this.src.indexOf(bucket) > -1) {
            validationUrl = true;
          }
        },
      );

      if (!this.src.startsWith("http") || !validationUrl || !this.size) {
        this.cloudSrc = this.src;
      } else {
        this.cloudSrc = ImageKitUrl.get(
          this.src,
          ImageKitUrl.getTransformationFromNumber(this.size),
        );
      }
    },
    applySize() {
      const ref = this.$refs.imgResize as InstanceType<typeof DsLazyImage>;
      if (!ref) {
        return;
      }

      const widthInner = (ref.$el as HTMLElement).offsetWidth;
      const heightInner = (ref.$el as HTMLElement).offsetHeight;
      const maxSize = sizes[sizes.length - 1];
      const maxContainerSize =
        widthInner > heightInner && widthInner < maxSize
          ? widthInner
          : heightInner;

      const sizesFinder = sizes.filter((e: number) => {
        return e >= maxContainerSize;
      });

      sizesFinder.sort((a: number, b: number) => a - b);
      if (sizesFinder.length) {
        this.size = sizesFinder.values().next().value;
      }
    },
  },
});
</script>
