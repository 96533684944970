/**
 * This interface describes the errors received from the API.
 *
 * The data object could vary for each endpoint, explaining the "any" type.
 *
 * @interface ApiHeaders
 * @memberOf ApiService
 * @property Authorization {string} Authorization
 * @property Content-type {string} Content type
 */
import { ApiErrorValidationTranslator } from "@/core/shared/helpers/Error/ApiErrorValidationTranslator";

// eslint-disable-next-line
export interface ApiServiceErrorData<T = any> {
  [fieldName: string]: T;
}

export interface ApiServiceErrorValidationData extends ApiServiceErrorData {
  [fieldName: string]: ApiServiceErrorValidationField;
}

export interface ApiServiceErrorValidationField {
  [ruleName: string]: ApiServiceErrorValidationRule;
}

// eslint-disable-next-line
export interface ApiServiceErrorValidationRuleData<T = any> {
  [fieldName: string]: T;
}

export interface ApiServiceErrorValidationRule {
  message: string;
  data: ApiServiceErrorValidationRuleData;
}

/**
 * @class ApiServiceError
 * @extends Error
 * @property name {string} <i>ApiServiceError<i>
 * @property message {string} Message
 * @property code {string} Code
 * @property data {ApiServiceErrorData} Details
 */
export class ApiServiceError extends Error {
  public code: string;
  public data: ApiServiceErrorData;

  /**
   * Constructor
   * @constructs
   * @param code Code of the error
   * @param message Message of error
   * @param data Object with details of the error
   */
  constructor(code: string, message: string, data: ApiServiceErrorData) {
    super(message);
    this.code = code;
    this.data = data;
  }

  getValidationFields() {
    return this.data;
  }

  getValidationFieldNames() {
    return Object.keys(this.data);
  }

  getValidationFieldRules(field: string) {
    return this.data[field];
  }

  getValidationFieldRuleNames(field: string) {
    return Object.keys(this.getValidationFieldRules(field));
  }

  getValidationFieldRule(field: string, ruleName: string) {
    return this.data[field][ruleName];
  }

  getTranslatedValidationRule(
    ruleName: string,
    rule: ApiServiceErrorValidationRule,
  ) {
    return ApiErrorValidationTranslator.translate(ruleName, rule);
  }

  getFirstTranslatedValidationRule() {
    const firstField = this.getValidationFieldNames()[0];
    const firstRuleName = this.getValidationFieldRuleNames(firstField)[0];
    const firstRule = this.getValidationFieldRule(firstField, firstRuleName);
    return this.getTranslatedValidationRule(firstRuleName, firstRule);
  }
}

/**
 * @class UnauthorizedApiServiceError
 * @extends Error
 * @property name {string} <i>ApiServiceError<i>
 * @property message {string} Message
 * @property code {string} Code
 * @property data {ApiServiceErrorData} Details
 */
export class UnauthorizedApiServiceError extends ApiServiceError {
  /**
   * Constructor
   * @constructs
   * @param code Code of the error
   * @param message Message of error
   * @param data {any} Object with details of the error
   */
  constructor(code: string, message: string, data: ApiServiceErrorData) {
    super(code, message, data);
  }
}

/**
 * @class MaintenanceApiServiceError
 * @extends Error
 * @property name {string} <i>ApiServiceError<i>
 * @property message {string} Message
 * @property code {string} Code
 * @property data {ApiServiceErrorData} Details
 */
export class MaintenanceApiServiceError extends ApiServiceError {
  /**
   * Constructor
   * @constructs
   * @param code Code of the error
   * @param message Message of error
   * @param data {any} Object with details of the error
   */
  constructor(code: string, message: string, data: ApiServiceErrorData) {
    super(code, message, data);
  }
}

/**
 * @class BadRequestApiServiceError
 * @extends Error
 * @property name {string} <i>ApiServiceError<i>
 * @property message {string} Message
 * @property code {string} Code
 * @property data {ApiServiceErrorData} Details
 */
export class BadRequestApiServiceError extends ApiServiceError {
  /**
   * Constructor
   * @constructs
   * @param code Code of the error
   * @param message Message of error
   * @param data {any} Object with details of the error
   */
  constructor(code: string, message: string, data: ApiServiceErrorData) {
    super(code, message, data);
  }
}
