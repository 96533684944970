import { defineStore } from "pinia";
import type { StoreNotification } from "@/core/shared/helpers/Notifier/Notifier";

export interface NotifierState {
  notifications: StoreNotification[];
  maintenanceNotificationId: number;
  connectivityNotificationId: number;
  networkNotificationId: number;
}

export const useNotifierStore = defineStore("notifier", {
  state: (): NotifierState => ({
    notifications: [] as StoreNotification[],
    maintenanceNotificationId: 0,
    connectivityNotificationId: 0,
    networkNotificationId: 0,
  }),
  actions: {
    add(notification: StoreNotification): void {
      this.notifications.push(notification);
    },
    delete(id: number): void {
      const index = this.notifications.findIndex((notification) => {
        return notification.id === id;
      });
      //If index found, delete the notification
      if (index !== -1) {
        this.notifications.splice(index, 1);
      }
    },
    setMaintenanceId(id: number): void {
      this.maintenanceNotificationId = id;
    },
    setConnectivityId(id: number): void {
      this.connectivityNotificationId = id;
    },
    setNetworkId(id: number): void {
      this.networkNotificationId = id;
    },
  },
});
