<template>
  <TransitionRoot as="template" :show="isShowing">
    <Dialog as="div" class="relative z-40" @close="onClose">
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-900 bg-opacity-30 transition-opacity"
        />
      </TransitionChild>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div
            class="pointer-events-none fixed inset-y-0 right-0 flex max-w-[304px] w-full"
          >
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div
                  class="flex h-full flex-col bg-white pt-6 shadow-xl relative"
                >
                  <div class="px-6 border-b border-gray-200 pb-4">
                    <div class="flex items-start justify-between">
                      <DsAvatar :alt="fullName" size="md" />
                      <div class="ml-3 flex h-7 items-center">
                        <button
                          class="cursor-pointer text-gray-900 h-8 w-8"
                          @click="isShowing = !isShowing"
                        >
                          <CloseOutlineIcon />
                        </button>
                      </div>
                    </div>
                    <p class="text-lg font-medium text-gray-900 pt-2">
                      {{ fullName }}
                    </p>
                    <p class="text-gray-500 text-sm font-normal">
                      {{ email }}
                    </p>
                  </div>
                  <div class="relative my-6 flex-1 overflow-y-auto">
                    <ul>
                      <li
                        v-for="(item, key) in items"
                        :key="key"
                        class="space-y-2 flex flex-col px-4"
                      >
                        <DsLink
                          @click="onClose"
                          :data-testid="item.dataTestid"
                          :to="item.route"
                          :class="[
                            isActive(item)
                              ? 'link-offcanvas-active'
                              : 'link-offcanvas',
                            item.cssClasses,
                          ]"
                        >
                          {{ item.label }}
                        </DsLink>
                      </li>
                    </ul>
                    <ul class="border-t border-gray-200 mt-4 pt-4">
                      <li
                        v-for="(item, key) in itemsAccount"
                        :key="key"
                        class="space-y-2 flex flex-col px-4"
                      >
                        <DsLink
                          v-if="Object.keys(item.route).length > 0"
                          @click="onClose"
                          :data-testid="item.dataTestid"
                          :to="item.route"
                          :class="[
                            isActive(item)
                              ? 'link-offcanvas-active'
                              : 'link-offcanvas',
                            item.cssClasses,
                          ]"
                        >
                          {{ item.label }}
                        </DsLink>
                        <DsButton
                          v-else
                          theme-display="link"
                          @click.prevent="callFunction(item)"
                          :data-testid="item.dataTestid"
                          class="link-offcanvas text-left"
                        >
                          {{ item.label }}
                        </DsButton>
                      </li>
                    </ul>
                    <!-- /End replace -->
                  </div>
                  <div
                    class="border-t border-gray-200 bg-white bottom-0 p-4 w-full"
                  >
                    <DsLink
                      :to="{ name: 'Logout' }"
                      class="block link-offcanvas"
                    >
                      {{ $t("common.route.logout.link") }}
                    </DsLink>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts">
/**
 * OffCanvas component
 * @version 2.0.0 - 2022-06-1
 */
import { computed, defineComponent } from "vue";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import type { PropType } from "vue";
import type { LocationAsRelativeRaw } from "vue-router";

import { DsAvatar, DsButton, DsLink } from "@devsalsa/vue-core";

import CloseOutlineIcon from "@/shared/components/icon/CloseOutlineIcon.vue";

import type { NavigationItem } from "@/core/shared/components/Navigation/Navigation.types";

//import core services
import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";

export default defineComponent({
  name: "OffCanvas",
  components: {
    DsAvatar,
    CloseOutlineIcon,
    DsLink,
    TransitionRoot,
    Dialog,
    DialogPanel,
    TransitionChild,
    DsButton,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array as PropType<NavigationItem[]>,
      required: true,
    },
    itemsAccount: {
      type: Array as PropType<NavigationItem[]>,
      required: true,
    },
  },
  data() {
    return {
      accountInfo: computed(
        () =>
          AccountHandler.accountInfo.value ?? {
            first_name: "",
            last_name: "",
            email: "",
          },
      ),
    };
  },
  watch: {
    grid(val) {
      this.isShowing = this.isShowing && val;
    },
  },
  computed: {
    grid() {
      return this.$grid("xl:hidden");
    },
    isShowing: {
      get() {
        return this.isOpen;
      },
      set(val: boolean) {
        this.$emit("update:isOpen", val);
      },
    },
    email(): string {
      return `${this.accountInfo.email || ""}`;
    },
    fullName(): string {
      return `${this.accountInfo.first_name || ""} ${
        this.accountInfo.last_name || ""
      }`;
    },
  },
  methods: {
    onClose() {
      this.isShowing = false;
    },
    isActive(item: NavigationItem): boolean {
      return this.$route.name === (item.route as LocationAsRelativeRaw).name;
    },
    callFunction(item: NavigationItem): void {
      if (item.action && typeof item.action === "function") {
        this.onClose();
        item.action();
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.link-offcanvas {
  @apply text-gray-500 text-sm font-medium p-2 no-underline hover:no-underline
  hover:text-gray-900 hover:bg-gray-100 hover:rounded-lg;
  &-active {
    @apply text-sm font-medium py-2 px-2 text-gray-900 bg-gray-100 rounded-lg no-underline hover:no-underline;
  }
}
</style>
