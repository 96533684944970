<template>
  <router-link
    :to="to"
    class="project-menu-item group"
    :class="[isSelected ? 'text-gray-900' : customClass]"
  >
    <slot name="icon" class="project-menu-item-icon"></slot>
    <span v-if="!hasSlotTitle" class="project-menu-item">
      {{ title }}
    </span>
    <slot v-else :name="idSlot" />
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProjectMenuItem",
  props: {
    to: {
      type: Object,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    iconClass: {
      type: String,
      default: "fill-jb-gray-400",
    },
    customClass: {
      type: String,
      default: "text-gray-500",
    },
  },
  data() {
    return {
      idSlot: "title",
    };
  },
  computed: {
    isSelected(): boolean {
      const isRouteChild =
        !!this.$route.meta.breadcrumb &&
        this.$route.meta.breadcrumb === this.routeName;

      return this.$route.name === this.routeName || isRouteChild;
    },
    hasSlotTitle(): boolean {
      return Reflect.has(this.$slots, this.idSlot);
    },
  },
});
</script>

<style lang="scss" scoped>
.project-menu-item {
  @apply flex items-center text-sm font-medium rounded-md transition-colors duration-100;

  &-icon {
    @apply h-5 w-5 block mr-4 shrink-0 group-hover:fill-gray-900;
  }

  &-label {
    @apply flex-1 group-hover:text-gray-900;
  }
}
</style>
